export const getCurrencyExchangeRate = (feeStructureData) => {
  const selectedCurrency = feeStructureData.feeStructure.state.currency;
  const currencyExchangeRates = feeStructureData.currency.conversions;
  const filteredSelectedExchangeRate = currencyExchangeRates.filter((currency) => currency.key === selectedCurrency);

  if (!filteredSelectedExchangeRate || filteredSelectedExchangeRate.length <= 0) {
    throw Error("Unable to find exchange rate for selected country");
  }

  return filteredSelectedExchangeRate[0].value;
};

export const calculateConvertedPrice = (price, exchangeRate) => Number((price * exchangeRate).toFixed(0));
