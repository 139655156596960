import { normalizePrefillFrequency } from "./normalizePrefillFrequency";

const fromCents = (donationAmount) => Number(donationAmount) / 100;

export const addCustomQuestionsToPrefill = (prefill) => {
  if (!prefill) {
    return {};
  }

  const pluckCustomFieldKeys = Object.keys(prefill).filter((key) => key.startsWith("customField"));

  return pluckCustomFieldKeys.reduce((field, key) => {
    field[key] = prefill[key];

    return field;
  }, {});
};

export const remapPrefillValues = (prefill) => {
  if (!prefill) {
    return null;
  }

  const prefillCustomQuestions = addCustomQuestionsToPrefill(prefill);

  return {
    address: prefill.address || null,
    donationAmount: prefill.donationAmount ? fromCents(prefill.donationAmount) : fromCents(prefill.amount?.amount),
    email: prefill.email || prefill.user.email,
    firstName: prefill.firstName || prefill.user.firstName,
    forRegularGiving: !!prefill.user,
    frequency: prefill.frequency ? normalizePrefillFrequency(prefill.frequency) : "",
    lastName: prefill.lastName || prefill.user.lastName,
    perk_answer: prefill.perk?.answer || null,
    perk_id: prefill.perk?.perk?.id || null,
    perk_multiplier: prefill.perk?.count || null,
    phone: prefill.phone || null,
    title: prefill.title || null,
    ...prefillCustomQuestions,
  };
};
