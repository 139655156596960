import React from "react";
import Avatar from "components/Avatar";
import asset from "helpers/cdn";

import alphabeticallyOrderedArray from "helpers/alphabeticallyOrderedArray";

export const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: "5px 0",
  }),
};

export const dropdownImageLabel = (fundraiser) => (
  <div className="fundraiser-dropdown__label">
    <div className="fundraiser-dropdown__avatar-container">
      <Avatar image={fundraiser.photo || fundraiser?.fundraiserProfile || asset("images/blueball.png")} alt="Fundraiser Image" />
    </div>
    <p className="fundraiser-dropdown__name" data-test="selected-fundraiser-name">{fundraiser.user_name || fundraiser?.fundraiserName}</p>
  </div>
);

export const hasValidTeamNames = (
  teamNames,
) => teamNames.length >= 2 || (teamNames.length === 1 && teamNames[0] !== null);

export const getAllTeamNames = (fundraisers) => {
  const teamNames = fundraisers.flatMap((fundraiser) => fundraiser.team_name);

  return teamNames?.length >= 1 ? teamNames.filter((value, index, team) => team.indexOf(value) === index) : [];
};

export const filterFundraisersByTeamName = (
  fundraisers,
  team,
) => fundraisers.filter((fundraiser) => fundraiser.team_name === team);

export const getTeamFundraiserOptions = (fundraisers, team) => {
  const options = [];

  const teamFundraisers = filterFundraisersByTeamName(fundraisers, team);

  teamFundraisers.forEach((fundraiser) => {
    options.push({
      name: `${fundraiser.id}`,
      label: dropdownImageLabel(fundraiser),
      value: `${fundraiser.id}`,
    });
  });

  return options;
};

export const isMainCampaign = (formProps, recipientId) => formProps.values.targetId === Number(recipientId);

export const getIndividualFundraiserFromOptions = (fundraiserDropdownOptions, targetId) => fundraiserDropdownOptions
  .map((fundraiserDropdown) => fundraiserDropdown.options)
  .flat()
  .find((fundraiser) => fundraiser?.value === String(targetId));

export const generateTargetDefault = ({
  formProps,
  fundraiserDropdownOptions,
  donationFormData: {
    recipientId,
  },
}) => {
  const preselectMainCampaign = false;

  if (preselectMainCampaign && !formProps.values.targetId) {
    return {
      value: fundraiserDropdownOptions[0].value,
      label: fundraiserDropdownOptions[0].label,
    };
  }

  if (formProps.values.targetId && !isMainCampaign(formProps, recipientId)) {
    const fundraiser = getIndividualFundraiserFromOptions(fundraiserDropdownOptions, formProps.values.targetId);

    return {
      value: fundraiser.value,
      label: fundraiser.label,
    };
  }
};

export const generateOptions = (donationFormData, donationFormContext) => {
  const options = [];

  const { target } = donationFormContext;

  if (!donationFormData || !donationFormContext || !target) {
    return;
  }

  const {
    recipientId,
    recipientOwner,
    fundraisers,
  } = donationFormData;

  const normalizeRecipientId = Number(recipientId);

  if (target.id !== normalizeRecipientId) {
    options.push({
      name: `${normalizeRecipientId}`,
      label: dropdownImageLabel({
        user_name: recipientOwner || donationFormData.recipient.title,
        photo: donationFormData.recipient?.photo,
      }),
      value: `${normalizeRecipientId}`,
    });
  }

  if (target.id === normalizeRecipientId) {
    options.push({
      name: `${normalizeRecipientId}`,
      value: `${normalizeRecipientId}`,
      label: dropdownImageLabel({
        user_name: recipientOwner || donationFormData.recipient.title,
        photo: donationFormData.recipient?.photo,
      }),
    });
  }

  if (fundraisers !== null) {
    const fundraiserOptions = [];

    const orderByKey = donationFormContext.fundraiserOptionsKey || "user_name";

    const alphabeticallyOrderedFundraisers = alphabeticallyOrderedArray(fundraisers, orderByKey);

    const teamNames = getAllTeamNames(fundraisers);

    if (hasValidTeamNames(teamNames)) {
      teamNames.forEach((team) => {
        options.push({
          label: team || "Individual Fundraisers",
          options: getTeamFundraiserOptions(alphabeticallyOrderedFundraisers, team, target),
        });
      });
    } else {
      alphabeticallyOrderedFundraisers.forEach((fundraiser) => {
        fundraiserOptions.push({
          name: `${fundraiser.id}`,
          label: dropdownImageLabel(fundraiser),
          value: `${fundraiser.id}`,
        });
      });

      options.push({
        label: "Fundraisers",
        options: fundraiserOptions,
      });
    }
  }

  return options;
};
