import { Elements, useStripe } from "@stripe/react-stripe-js";
import React from "react";
import StripePaymentElement from "./StripePaymentElement";

export default function StripePaymentElementWrapper({
  data,
  formProps,
  handlers,
  donationFormContext,
}) {
  const stripePromise = useGetStripe();
  const appearance = {
    theme: "flat",
    variables: {
      fontSizeBase: "14px",
      fontWeightNormal: "500",
      colorPrimary: "#1fa8df",
      fontFamily: "'Source Sans Pro', 'Helvetica Neue', helvetica, arial, sans-serif",
    },
  };
  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: donationFormContext.donation?.paymentIntentsId, appearance }}
    >
      <div className="well py-1">
        <StripePaymentElement
          data={data}
          formProps={formProps}
          handlers={handlers}
          donationFormContext={donationFormContext}
        />
      </div>
    </Elements>
  );
}

export const useGetStripe = () => {
  const stripeKey = window.Chuffed && window.Chuffed.donationInit.stripePublishableKey;
  const stripePromise = useStripe(stripeKey);

  return stripePromise;
};
