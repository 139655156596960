import { calculateOptionalAmount } from "helpers/calculateOptionalAmount";
import { calculateProcessingFee } from "helpers/calculateProcessingFee";
import { getDisclaimerField } from "widgets/Donation/components/Disclaimer/createDisclaimer";
import { hasBacsPaymentMethod } from "./getPaymentMethodOptions";

const perkHasRegularGiving = (selectedPerk) =>
  selectedPerk?.regular_giving_monthly
  || selectedPerk?.regular_giving_weekly
  || selectedPerk?.regular_giving_yearly;

export const onlyPayPalPayment = (
  paymentMethodPayPal,
  paymentMethodStripe,
  paymentIntentsId,
) => paymentMethodPayPal && (!paymentMethodStripe || !paymentIntentsId);

export const isValidPaymentIntent = (paymentIntentsId) =>
  paymentIntentsId !== null
  && paymentIntentsId !== undefined
  && paymentIntentsId !== "";

export const donationFormEnabled = (donationFormData, paymentIntentsId) => {
  const {
    paymentVendors,
    recipient: { camp_status },
  } = donationFormData;

  if (camp_status === "draft") {
    return true;
  }

  if (paymentVendors.length === 1 && paymentVendors.includes("paypal")) {
    return true;
  }

  return hasValidPaymentVendor(paymentVendors, paymentIntentsId);
};

function hasValidPaymentVendor(paymentVendors, paymentIntentsId) {
  const hasPaymentVendors = paymentVendors.length >= 1;
  const isPayPalIncluded = paymentVendors.includes("paypal");
  const isValidPI = isValidPaymentIntent(paymentIntentsId);

  return hasPaymentVendors && (isValidPI || isPayPalIncluded);
}

export const isCardPayment = (paymentMethod) =>
  paymentMethod === "visa-mc" || paymentMethod === "amex";

export const handleDonationAmountChanges = async (
  donationAmountValue,
  campaignData,
  formProps,
  optionalTier = 2,
) => {
  if (!donationAmountValue) {
    return;
  }

  const { feeStructure } = campaignData;

  const {
    values: { paymentMethod, chuffedAmount },
  } = formProps;

  formProps.setFieldValue("donationAmount", donationAmountValue);

  // Set the hidden value of the fee amount
  const fee = calculateProcessingFee(
    donationAmountValue,
    paymentMethod,
    feeStructure,
  );
  formProps.setFieldValue("feeAmount", fee);

  let selectedTier = optionalTier;
  // Handle the case of fewer optional options
  const chuffedAmountOrDefault = chuffedAmount || 0;
  if (
    optionalTier === 0
    && calculateOptionalAmount(
      donationAmountValue,
      fee,
      optionalTier,
      feeStructure,
      chuffedAmountOrDefault,
    ) === null
  ) {
    selectedTier = 1;
  }
  // Set the hidden value of the optional amount to Chuffed
  // based on the selected value of the dropdown
  const optionalAmountValue = calculateOptionalAmount(
    donationAmountValue,
    fee,
    selectedTier,
    feeStructure,
    chuffedAmountOrDefault,
  );
  formProps.setFieldValue("chuffedAmount", optionalAmountValue);
};

export const handlePaymentMethodChanges = async (
  paymentMethodValue,
  campaignData,
  formProps,
  optionalTier = 2,
) => {
  if (!paymentMethodValue) {
    return;
  }

  const { feeStructure } = campaignData;

  const {
    values: { donationAmount, chuffedAmount },
  } = formProps;

  // Set the hidden value of the fee amount
  const fee = calculateProcessingFee(
    donationAmount,
    paymentMethodValue,
    feeStructure,
  );
  formProps.setFieldValue("feeAmount", fee);

  let selectedTier = optionalTier;
  // Handle the case of fewer optional options
  const chuffedAmountOrDefault = chuffedAmount ?? 0;
  if (
    optionalTier === 0
    && calculateOptionalAmount(
      donationAmount,
      fee,
      optionalTier,
      feeStructure,
      chuffedAmountOrDefault,
    ) === null
  ) {
    selectedTier = 1;
  }
  // Set the hidden value of the optional amount to Chuffed
  // based on the selected value of the dropdown
  const optionalAmountValue = calculateOptionalAmount(
    donationAmount,
    fee,
    selectedTier,
    feeStructure,
    chuffedAmountOrDefault,
  );

  formProps.setFieldValue("chuffedAmount", optionalAmountValue ?? 0);
};

export const isSharedModel = (feeStructure) =>
  feeStructure
  && feeStructure?.feesByPaymentType === false
  && feeStructure?.optionalPlatformDonations === true;
export const isFlatFeeModel = (feeStructure) =>
  feeStructure
  && feeStructure?.feesByPaymentType === false
  && feeStructure?.optionalPlatformDonations === false;

export const shouldDisplayPaymentMethod = (type, { ...props }) => {
  const {
    data: { feeStructure },
    paymentMethod,
  } = props;

  const shared = isSharedModel(feeStructure);
  const flatFee = isFlatFeeModel(feeStructure);

  if (type === "paypal") {
    if (props?.frequency !== "one-off") {
      return false;
    }

    if (!paymentMethod) {
      return true;
    }

    if (shared || flatFee || paymentMethod === "paypal") {
      return true;
    }
  }

  if (type === "stripe") {
    if (!paymentMethod) {
      return true;
    }

    if (shared || flatFee || isCardPayment(paymentMethod)) {
      return true;
    }
  }

  if (
    type === "autoPaymentRequest"
    && props?.donationFormContext?.paymentRequest
  ) {
    if (!paymentMethod) {
      return true;
    }

    if (shared || flatFee || isCardPayment(paymentMethod)) {
      return true;
    }
  }

  return false;
};

export const renderDonationFormError = (paymentVendors, paymentIntentsId) => {
  if (paymentVendors.length <= 0) {
    return "This campaign has no payment options enabled. Please contact the campaigner to ask them to enable payment options.";
  }

  const isPaypalMethod = paymentVendors.includes("paypal");
  return (
    paymentVendors.length >= 1
    && !isPaypalMethod
    && !paymentIntentsId
    && "We have noticed suspicious activity. Please try again later."
  );
};

export const hasPaypal = (donationFormData) =>
  donationFormData.paymentMethodPayPal
  || donationFormData.paymentVendors.includes("paypal");
export const hasStripe = (donationFormData) =>
  donationFormData.paymentMethodStripe
  || donationFormData.paymentVendors.includes("stripeau");

export const isCustomTemplate = (donationFormData) =>
  donationFormData?.customTemplate !== null;

const invalidStripeCountryCodes = [
  {
    countryCode: "ZA",
    valid: "US",
  },
];

export const shouldChangeCountryCode = (countryCode) => {
  if (!countryCode) {
    return "AU";
  }

  const isInvalidCode = invalidStripeCountryCodes.map(
    (code) => code.countryCode === countryCode && code.valid,
  );
  return isInvalidCode[0] ? isInvalidCode[0] : countryCode;
};

// Ensure the recipient owner name is displayed correctly for the payment request
// e.g., in browser windows, on devices
export const decodeRecipientOwner = (ownerName) => {
  const owner = new DOMParser().parseFromString(ownerName, "text/html");

  return owner.documentElement.textContent;
};

const stepHeadingNumberMap = {
  // null step heading means section is hidden and does not require a heading
  FUNDRAISER_DROPDOWN: {
    default: null,
    hasFundraisersPaymentSelectionHidden: 1,
    hasFundraisersHasPaymentSelection: 1,
    noFundraisersPaymentSelectionHidden: null,
    noDetails: 1,
    hasFundraisersPaymentSelectionHiddenNoDetails: 1,
    hasFundraisersHasPaymentSelectionNoDetails: 1,
    noFundraisersPaymentSelectionHiddenNoDetails: 1,
    onlyPaymentDetailsFlatFee: null,
  },
  PAYMENT_METHOD: {
    default: 1,
    hasFundraisersPaymentSelectionHidden: null,
    hasFundraisersHasPaymentSelection: 2,
    noDetails: 1,
    noFundraisersPaymentSelectionHidden: null,
    hasFundraisersPaymentSelectionHiddenNoDetails: null,
    hasFundraisersHasPaymentSelectionNoDetails: 2,
    noFundraisersPaymentSelectionHiddenNoDetails: null,
    onlyPaymentDetailsFlatFee: null,
  },
  DONATION: {
    default: 2,
    hasFundraisersPaymentSelectionHidden: 2,
    hasFundraisersHasPaymentSelection: 3,
    noFundraisersPaymentSelectionHidden: 1,
    noDetails: 2,
    hasFundraisersPaymentSelectionHiddenNoDetails: 2,
    hasFundraisersHasPaymentSelectionNoDetails: 3,
    noFundraisersPaymentSelectionHiddenNoDetails: 1,
    onlyPaymentDetailsFlatFee: null,
  },
  DETAILS: {
    default: 3,
    hasFundraisersPaymentSelectionHidden: 3,
    hasFundraisersHasPaymentSelection: 4,
    noFundraisersPaymentSelectionHidden: 2,
    noDetails: null,
    hasFundraisersPaymentSelectionHiddenNoDetails: null,
    hasFundraisersHasPaymentSelectionNoDetails: null,
    noFundraisersPaymentSelectionHiddenNoDetails: null,
    onlyPaymentDetailsFlatFee: 1,
  },
  PAYMENT: {
    default: 4,
    hasFundraisersPaymentSelectionHidden: 4,
    hasFundraisersHasPaymentSelection: 5,
    noFundraisersPaymentSelectionHidden: 3,
    noDetails: 3,
    hasFundraisersPaymentSelectionHiddenNoDetails: 3,
    hasFundraisersHasPaymentSelectionNoDetails: 4,
    noFundraisersPaymentSelectionHiddenNoDetails: 2,
    onlyPaymentDetailsFlatFee: 2,
  },
};

const stepHeadingMap = (donationType) => ({
  PAYMENT_METHOD: "Select a payment method",
  FUNDRAISER_DROPDOWN: "Choose who your donation will be assigned to",
  DONATION: `Choose your ${donationType}`,
  DETAILS: "Your details",
  PAYMENT: "Payment details",
});

export const getIndividualStepHeading = ({
  hasFundraisers,
  paymentMethodSelectorHidden,
  donationType,
  fieldset,
  paymentDetailsOnly,
}) => {
  const heading = stepHeadingMap(donationType)[fieldset];

  if (paymentDetailsOnly) {
    return `${stepHeadingNumberMap[fieldset].onlyPaymentDetailsFlatFee}. ${heading}`;
  }

  if (hasFundraisers && paymentMethodSelectorHidden) {
    return `${stepHeadingNumberMap[fieldset].hasFundraisersPaymentSelectionHidden}. ${heading}`;
  }

  if (hasFundraisers && !paymentMethodSelectorHidden) {
    return `${stepHeadingNumberMap[fieldset].hasFundraisersHasPaymentSelection}. ${heading}`;
  }

  if (!hasFundraisers && paymentMethodSelectorHidden) {
    return `${stepHeadingNumberMap[fieldset].noFundraisersPaymentSelectionHidden}. ${heading}`;
  }

  return `${stepHeadingNumberMap[fieldset].default}. ${heading}`;
};

const getDonationType = (showDonation, perks, levels) => {
  if (!showDonation) {
    if (perks) {
      return "perk options";
    }
  }

  if (levels.length > 0) {
    return "impact";
  }

  return "donation";
};

export const getStepHeading = (stepKey, { ...props }, showDonation) => {
  const {
    paymentMethodPayPal,
    paymentMethodStripe,
    feeStructure,
    perks,
    levels,
    paymentIntentsId,
    fundraisers,
  } = props;

  const flatFee = isFlatFeeModel(feeStructure);
  const hasFundraisers = fundraisers !== null && fundraisers?.length > 0;
  const onlyPaypal = onlyPayPalPayment(
    paymentMethodPayPal,
    paymentMethodStripe,
    paymentIntentsId,
  );
  const paymentMethodSelectorHidden = onlyPaypal || feeStructure.feesByPaymentType === false;
  const donationType = getDonationType(showDonation, perks, levels);
  const paymentDetailsOnly = flatFee;
  const headingParams = {
    hasFundraisers,
    paymentMethodSelectorHidden,
    donationType,
    fieldset: stepKey,
    paymentDetailsOnly,
  };

  const availableSteps = [
    "FUNDRAISER_DROPDOWN",
    "PAYMENT_METHOD",
    "DONATION",
    "DETAILS",
    "PAYMENT",
  ];
  if (availableSteps.indexOf(stepKey) === -1) {
    return "";
  }

  return getIndividualStepHeading(headingParams);
};

export const shouldDisplayPaymentMethodSelection = (
  donationFormData,
  elements,
) => {
  const {
    paymentVendors,
    feeStructure: { optionalPlatformDonations, feesByPaymentType },
  } = donationFormData;

  const isKeep100Model = !isSharedModel({ optionalPlatformDonations, feesByPaymentType })
    && !isFlatFeeModel({ optionalPlatformDonations, feesByPaymentType });

  if (
    paymentVendors.length >= 1
    && hasStripe(donationFormData)
    && isKeep100Model
  ) {
    elements.push("paymentMethodSectionSubheading");
    elements.push("paymentSelector");
    return true;
  }

  return false;
};

export const shouldDisplayCampaignCard = (donationFormData, elements) =>
  !isCustomTemplate(donationFormData) && elements.push("campaignCard");

export const shouldDisplayDisclaimer = (donationFormData, elements) =>
  getDisclaimerField(donationFormData.donationFormCustomFields)
  && elements.push("disclaimer");

export const shouldDisplayMatchedGiving = (donationFormData, elements) => {
  const { matchedGiving } = donationFormData;

  if (matchedGiving) {
    elements.push("matchedGiving");
  }
};

export const shouldDisplayDirectDebit = (
  donationFormData,
  donationFormContext,
  elements,
) => {
  const {
    directDebitCheckoutSession,
    stripeDirectDebit,
    paymentVendors,
  } = donationFormData;

  const { gqlLoadingErrors } = donationFormContext;
  const hasBacsEnabled = hasBacsPaymentMethod(donationFormData);

  if (
    directDebitCheckoutSession !== null
    && stripeDirectDebit
    && !gqlLoadingErrors.directDebitError
    && paymentVendorsHas(paymentVendors, "stripe")
    // If BACS is enabled, we will use Payment Elements instead, so don't show this form
    && !hasBacsEnabled
  ) {
    elements.push("directDebitDonation");
  }
};

const paymentVendorsHas = (vendors, vendorPrefix) => {
  const vendor = vendors.find((v) => v.startsWith(vendorPrefix));
  return vendor !== undefined;
};

export const shouldDisplayFundraisersDropdown = (
  donationFormData,
  elements,
) => {
  const { fundraisers } = donationFormData;

  if (fundraisers !== null && fundraisers?.length > 0) {
    elements.push("fundraisersDropdownSubheading");
    elements.push("fundraisersDropdown");
  }
};

export const typeOfdonationSelector = (
  donationFormData,
  donationFormContext,
  elements,
) => {
  const {
    levels, donationFormMode, selectedPerk, prefill,
  } = donationFormData;

  const { hasPerk } = donationFormContext;

  if (levels.length >= 1 && donationFormMode === "ImpactLevel") {
    elements.push("impactLevels");
  } else if (
    ((hasPerk && selectedPerk) || prefill?.perk_id)
    && donationFormMode === "Perk"
  ) {
    elements.push("perksSelector");
  } else {
    elements.push("donationSelector");
  }
};

export const shouldDisplayRegularGiving = (donationFormData, elements) => {
  const { regularGiving, selectedPerk, prefill } = donationFormData;

  if (
    regularGiving.enabled
    || perkHasRegularGiving(selectedPerk)
    || prefill?.perk_id
  ) {
    elements.push("regularGiving");
    elements.push("regularGivingFrequencyLabel");
  }
};

export const shouldDisplayCampaignRecipientLabel = (
  donationFormData,
  elements,
) => {
  const {
    feeStructure: { optionalPlatformDonations, feesByPaymentType },
  } = donationFormData;

  if (!isFlatFeeModel({ optionalPlatformDonations, feesByPaymentType })) {
    // or not perk selected
    elements.push("donationCampaignLabel");
  }
};

export const shouldDisplayPaymentProcessingFees = (
  donationFormData,
  elements,
) => {
  const {
    feeStructure: { feesByPaymentType },
  } = donationFormData;

  if (feesByPaymentType) {
    elements.push("paymentProcessingFees");
  }
};

export const shouldDisplayOptionalDonationSelector = (
  donationFormData,
  elements,
) => {
  const {
    feeStructure: { optionalPlatformDonations },
  } = donationFormData;

  if (optionalPlatformDonations) {
    elements.push("optionalSelector");
  }
};

export const paymentVendorsToDisplay = (
  donationFormData,
  donationFormContext,
  elements,
) => {
  const { paymentVendors } = donationFormData;

  const {
    donation: { paymentIntentsId },
  } = donationFormContext;

  paymentVendors.forEach((vendor) => {
    if (vendor === "stripeau") {
      if (!isValidPaymentIntent(paymentIntentsId)) {
        return;
      }

      return elements.push("stripe") && elements.push("autoPayment");
    }

    return elements.push(vendor);
  });
};

export const shouldDisplayDetailsSection = (donationFormData, elements) => {
  elements.push("detailsSection", "detailsSectionSubheading");
};

export const shouldDisplayDonationSelectorHeading = (
  donationFormData,
  elements,
) => {
  const { feeStructure } = donationFormData;

  const flatFee = isFlatFeeModel(feeStructure);

  if (flatFee) {
    return;
  }

  elements.push("donationSectionSubheading");
};

export const shouldDisplayCustomQuestions = (donationFormData, elements) => {
  const {
    donationFormCustomFields,
  } = donationFormData;

  if (donationFormCustomFields?.length >= 1) {
    elements.push("customQuestions");
  }
};
