import { useEffect, useState } from "react";
import { PaymentMethodTypes as StripePaymentMethodTypes } from "widgets/Donation/types";

const useGetPaymentMethodTypes = (donationFormData) => {
  const [paymentMethodTypes, setPaymentMethodTypes] = useState([StripePaymentMethodTypes.Card]);

  const stripePaymentMethod = donationFormData.financialInformation.paymentMethods.find((method) => method.key === "stripeau");

  useEffect(() => {
    const paymentMethods = [StripePaymentMethodTypes.Card];

    if (
      stripePaymentMethod
      && stripePaymentMethod.paymentMethod.availableMethods.includes(StripePaymentMethodTypes.Bacs)
    ) {
      paymentMethods.push(StripePaymentMethodTypes.Bacs);
    }

    setPaymentMethodTypes(paymentMethods);
  }, [stripePaymentMethod]);

  return paymentMethodTypes;
};

export default useGetPaymentMethodTypes;
