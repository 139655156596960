const activeFormFieldStatuses = ['Enabled', 'Required'];
export const fieldRequired = (fieldStatus) => (fieldStatus === null || fieldStatus === void 0 ? void 0 : fieldStatus.toLowerCase()) === 'required' || (fieldStatus === null || fieldStatus === void 0 ? void 0 : fieldStatus.toLowerCase()) === 'locked';
export const fieldIsEnabled = (fieldStatus) => {
    if (!fieldStatus) {
        return false;
    }
    const normalizeFieldStatus = fieldStatus.toLowerCase();
    return ['required', 'locked', 'enabled'].indexOf(normalizeFieldStatus) !== -1;
};
export const donationFormHasAddress = (donationFormFields) => donationFormFields.some((field) => field.id === 'address' && activeFormFieldStatuses.includes(field.status));
export const getPaymentElementFieldOptions = (donationFormFields) => {
    const options = {
        fields: {
            billingDetails: {
                name: 'never',
                email: 'never',
            },
        },
        wallets: {
            applePay: 'never',
            googlePay: 'never',
        },
    };
    if (donationFormHasAddress(donationFormFields)) {
        options.fields.billingDetails.address = 'never';
    }
    return options;
};
