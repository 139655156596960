import SmoothScroll from 'smooth-scroll';
export default function ScrollToError(errors) {
    const firstErrorKey = Object.keys(errors)[0];
    const scroll = new SmoothScroll();
    const options = { speed: 800, easing: 'easeOutCubic', offset: 100 };
    const scrollToElem = document.getElementsByName(firstErrorKey)[0];
    if (scrollToElem) {
        scroll.animateScroll(scrollToElem, null, options);
    }
}
