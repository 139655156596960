import { isEmptyObject } from "helpers/emptyObject";
import arrayIsSubset from "helpers/arrayIsSubset";
import donationFormPrefillParams from "./donationFormPrefillParams";

const validateQueryParams = (queryParams) => {
  const paramKeys = Object.keys(queryParams);
  const mandatoryKeys = ["firstName", "lastName", "email"];

  const mandatoryKeysExist = arrayIsSubset(paramKeys, mandatoryKeys);

  if (mandatoryKeysExist) {
    return true;
  }

  return false;
};

const getPrefillData = (data) => {
  if (!data) {
    return null;
  }

  const queryParamsPrefill = donationFormPrefillParams();
  const validQueryParams = validateQueryParams(queryParamsPrefill);

  if (!isEmptyObject(queryParamsPrefill) && validQueryParams) {
    return { prefill: queryParamsPrefill };
  }

  if (data.paymentPrefill) {
    return { prefill: data.paymentPrefill };
  }

  if (data.viewer?.rememberPaymentDetails && data.viewer?.isVerified) {
    return { prefill: data.viewer };
  }

  return null;
};

export default getPrefillData;
