import React, { Component } from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { DebounceInput } from "react-debounce-input";

import classNames from "classnames";

import { FcCheckmark } from "@react-icons/all-files/fc/FcCheckmark";
import { omit } from "lodash/object";
import LoadingIndicator from "../LoadingIndicator";

import "./_style.scss";

class Input extends Component {
  static propTypes = {
    className: PropTypes.string,
    isInvalid: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isRequired: PropTypes.bool,
    isTextOnly: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.string,
    successMsg: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    isInvalid: false,
    isDisabled: false,
    isTextOnly: false,
    isRequired: false,
    placeholder: "",
    size: "regular",
    type: "text",
    value: "",
  };

  componentDidUpdate() {
    const {
      infoMsg,
    } = this.refs;

    const infoMsgEl = findDOMNode(infoMsg);

    if (!infoMsgEl) {
      return;
    }

    const infoMsgElDimensions = infoMsgEl.getBoundingClientRect();

    infoMsg.style.top = `-${infoMsgElDimensions.height + 20}px`;
    infoMsg.style.visibility = "visible";
  }

  render() {
    const {
      className,
      infoMsg,
      isInvalid,
      isDisabled,
      isRequired,
      isLoading,
      isTextOnly,
      onChange,
      placeholder,
      size,
      successMsg,
      type,
      ...other
    } = this.props;

    // this is to stop the dom from complaining about unsupported attributes on <input>
    const input = omit(
      other,
      "autofill",
      "autofilled",
      "visited",
      "touched",
      "active",
      "pristine",
      "initialValue",
      "onUpdate",
      "valid",
      "invalid",
      "dirty",
    );

    return (
      <span
        className={
          classNames(
            "input",
            className,
          )
        }
        data-test="input"
      >
        <DebounceInput
          debounceTimeout={500}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          disabled={isDisabled}
          required={isRequired}
          className={
            classNames(
              "input__element",
              `--${size}`,
              {
                "--isRequired": isRequired,
                "--isInvalid": isInvalid,
                "--text-only": isTextOnly,
              },
            )
          }
          readOnly={isTextOnly}
          {...input}
        />
        {successMsg
          && (
          <span className="input__successMsg">
            <FcCheckmark size="10" color="#28ab60" style={{ marginRight: "3px" }} />
            {successMsg}
          </span>
          )}
        {(other.active && infoMsg)
          && (
          <div
            className="input__infoMsg"
            ref="infoMsg"
          >
            <p
              dangerouslySetInnerHTML={{ __html: infoMsg }}
            />
          </div>
          )}
        {isLoading
          && <LoadingIndicator className="input__loadingIndicator" size="10px" />}
      </span>
    );
  }
}

export default Input;
