export const remapPerkQuestions = (perkQuestions) => {
  if (!perkQuestions) {
    return null;
  }

  return perkQuestions.map((question) => ({
    question: question.label,
    options: question.options,
  }))[0];
};

export const remapSelectedPerk = (perk) => {
  if (!perk) {
    return null;
  }

  const perkQuestions = remapPerkQuestions(perk.questions);

  return {
    price: perk.amount.amount / 100,
    campaign_id: perk.campaign.id,
    enable_question: perk.isQuestionEnabled,
    id: perk.id,
    is_hidden: perk.isHidden,
    multiple: perk.allowMultiple,
    one_off: perk.frequency.allowOneOff,
    quantity: perk.quantity.available,
    quantity_redeemed: perk.quantity.redeemed,
    regular_giving_monthly: perk.frequency.allowMonthly,
    regular_giving_weekly: perk.frequency.allowWeekly,
    regular_giving_yearly: perk.frequency.allowYearly,
    title: perk.title,
    type: "perk",
    question: perkQuestions,
  };
};

export const remapCollectivePerks = (perks) => {
  if (!perks) {
    return null;
  }

  const perkQuestions = remapPerkQuestions(perks.questions);

  return (
    {
      available: perks.quantity.available,
      description: perks.description,
      enable_question: perks.isQuestionEnabled,
      id: perks.id,
      multiple: perks.allowMultiple || false,
      price: perks.amount.amount / 100,
      quantity: perks.quantity.total,
      question: perkQuestions,
    }
  );
};
