export const remapFrequencyValues = (frequency) => {
  if (!frequency) {
    return null;
  }

  if (frequency.isEnabled) {
    return {
      enabled: frequency.isEnabled,
      monthly: frequency.allowMonthly,
      weekly: frequency.allowWeekly,
      yearly: frequency.allowYearly,
    };
  }

  return {
    enabled: false,
    monthly: false,
    weekly: false,
    yearly: false,
  };
};
