export const updateFeeAmounts = (isNew, feeConfirmation, setFieldValue) => {
  if (!feeConfirmation) {
    throw Error("No fee confirmation provided");
  }

  const {
    newChuffedAmount,
    newFeeAmount,
    newPaymentMethod,
    oldChuffedAmount,
    oldFeeAmount,
    oldPaymentMethod,
  } = feeConfirmation;

  const updatedChuffedAmount = (isNew ? newChuffedAmount : oldChuffedAmount) || 0;

  setFieldValue("chuffedAmount", updatedChuffedAmount);
  setFieldValue("feeAmount", (isNew ? newFeeAmount : oldFeeAmount));
  setFieldValue("paymentMethod", (isNew ? newPaymentMethod : oldPaymentMethod));
};

export const closeFeeConfirmation = (handlers) => {
  const {
    setFeeConfirmation,
    setProcessingSubmit,
  } = handlers;

  setFeeConfirmation({
    isVisible: false,
    isSubmittingFromConfirmation: false,
  });

  setProcessingSubmit(false);
};
