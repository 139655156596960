import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import LinkButton from "components/LinkButton";
import TextTranslator from "components/TranslateText/TextTranslator";
import { formatCurrency } from "helpers/formatCurrency";
import React, { useState } from "react";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { getPaymentElementFieldOptions } from "widgets/Donation/utils/fieldHelpers";
import { getPayButtonLabel, handleStripeSubmit } from "../utils";

export default function StripePaymentElement({
  data, formProps, handlers, donationFormContext,
}) {
  const {
    recipient: {
      camp_status,
    },
    feeStructure,
  } = data;

  const {
    donation: {
      isSubmitting,
      isError,
      errorMessage,
    },
  } = donationFormContext;

  const {
    setFieldValue,
  } = formProps;

  const [paymentType, setPaymentType] = useState(false);
  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  const elements = useElements();

  const handleCardChange = (e) => {
    setPaymentType(e?.value?.type);
    setFieldValue("selectedPaymentMethodType", e?.value?.type);
  };

  return (
    <div className="donation-form__payment-method" data-test="donation-form__stripe">
      <PaymentElement onChange={handleCardChange} options={getPaymentElementFieldOptions(data.donationFormFields)} />

      <LinkButton
        id="js-donate-action-stripe"
        isBold
        isDisabled={camp_status === "draft" || donationFormContext.isPaymentRequestMethod || donationTotal <= 0}
        isFullWidth
        isLoading={isSubmitting}
        loadingMessage={<TextTranslator>Submitting payment...</TextTranslator>}
        look="primary"
        type="submit"
        onClick={() => {
          setFieldValue("submitButton", "paymentElement");
          handlers.setPaymentElements(elements);
          handleStripeSubmit(handlers);
        }}
        size="large"
        className="mt-1"
      >
        <TextTranslator replacements={
          {
            currency: formatCurrency(donationTotal, feeStructure),
          }
        }
        >
          Pay :currency
        </TextTranslator>
        {" "}
        {getPayButtonLabel(paymentType)}
      </LinkButton>

      {isError && (
        <div className="donation-form__error">
          { errorMessage }
        </div>
      )}
    </div>
  );
}
