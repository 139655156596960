import getUrlParameter from 'helpers/getUrlParameter';
import Cookies from 'js-cookie';
const tags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
const retrieve = () => {
    const items = {};
    tags.forEach((key) => {
        const value = getUrlParameter(key) || Cookies.get(`cookie_${key}`);
        if (value) {
            items[key] = getUrlParameter(key) || Cookies.get(`cookie_${key}`) || '';
        }
    });
    return items;
};
export default retrieve;
