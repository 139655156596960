const updatePrefillFields = (prefill, formProps) => {
  if (!prefill) {
    return;
  }

  const prefillFields = {
    addressLine1: prefill.address?.street,
    addressLocality: prefill.address?.locality,
    addressRegion: prefill.address?.region,
    addressPostcode: prefill.address?.postcode,
    email: prefill.email,
    firstName: prefill.firstName,
    lastName: prefill.lastName,
    phone: prefill.phone,
    title: prefill.title,
  };

  return Object.keys(prefillFields).forEach((field) => {
    const fieldValue = prefillFields[field];
    formProps.setFieldValue(field, fieldValue);
  });
};

export default updatePrefillFields;
