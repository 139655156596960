import React from "react";

import LinkButton from "components/LinkButton";

import TextTranslator from "components/TranslateText/TextTranslator";
import { formatCurrency } from "helpers/formatCurrency";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { decodeRecipientOwner, shouldDisplayPaymentMethod } from "widgets/Donation/utils/helpers";
import { frequencyLabel } from "widgets/Donation/utils/regularGivingHelpers";

/**
 * !split this into separate files
*/

export const createPaymentRequestLabel = (feeStructure, data, formProps, donationFormContext) => {
  if (!formProps) {
    return;
  }

  const regularGivingContext = {
    ...data,
    ...formProps,
  };

  const {
    canMakePaymentType,
  } = donationFormContext;

  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  let method = "via stored card";
  let symbol = "";

  if (canMakePaymentType?.applePay) {
    method = "";
    symbol = " ";
  }

  if (canMakePaymentType?.googlePay) {
    method = "via Google Pay";
  }

  return (
    <TextTranslator replacements={
      {
        currency: formatCurrency(donationTotal, feeStructure),
        frequency: frequencyLabel(true, false, regularGivingContext),
        method,
        symbol,
      }
    }
    >
      :symbol Pay :currency:frequency :method
    </TextTranslator>
  );
};

export const handlePaymentRequestSubmit = (
  values,
  donationFormData,
  donationFormContext,
  handlers,
  setFieldTouched,
) => {
  setFieldTouched("cardField", false);
  handlers.setDonationValues(values);

  const {
    feeStructure,
    recipientOwner,
  } = donationFormData;

  const {
    paymentRequest,
    canMakePaymentType,
  } = donationFormContext;

  // Forces second display of Apple Pay on iphone after confirmation of fees, because
  // `Integration Error: You cannot update Payment Request options while the payment sheet is showing.`
  if (paymentRequest) {
    paymentRequest.update({
      currency: feeStructure.currency,
      total: {
        amount: Number.parseInt(calculateDonationTotal(feeStructure, {}, values) * 100, 10),
        label: `${decodeRecipientOwner(recipientOwner)}`,
      },
    });
  }

  if (paymentRequest || canMakePaymentType.applePay) {
    handlers.setIsPaymentRequestMethod(true);
    handlers.setIsForeignCurrencyApplePay(canMakePaymentType.applePay); // state change so on mobile it knows not to fix the payment amount

    paymentRequest.show();
  }
};

export const createAutoPaymentRequest = (data, i, formProps, handlers, donationFormContext) => {
  const {
    feeStructure,
    recipient: {
      camp_status,
    },
  } = data;

  const {
    values: { paymentMethod },
    setFieldValue,
  } = formProps;

  const {
    paymentRequest,
    donation: {
      isSubmitting,
      isError,
      errorMessage,
    },
  } = donationFormContext;

  if (!shouldDisplayPaymentMethod("autoPaymentRequest", { paymentMethod, data, donationFormContext })) {
    return null;
  }

  return (
    <div className="donation-form__payment-method">
      <LinkButton
        id="js-donate-action-payrequest"
        isBold
        isDisabled={camp_status === "draft" || isSubmitting && !paymentRequest}
        isFullWidth
        isLoading={isSubmitting && paymentRequest}
        loadingMessage={<TextTranslator>Submitting payment...</TextTranslator>}
        look="payrequest"
        type="submit"
        onClick={() => {
          setFieldValue("submitButton", "paymentRequest");
        }}
        size="large"
      >
        { createPaymentRequestLabel(feeStructure, data, formProps, donationFormContext) }
      </LinkButton>
      {
          (paymentRequest && isError)
            && (
              <div className="donation-form__error">
                { errorMessage }
              </div>
            )
        }
    </div>
  );
};
