import { render } from 'react-dom';
const mountApps = (apps) => {
    Object.keys(apps).forEach((id) => {
        const ele = document.getElementById(id);
        if (ele) {
            render(apps[id](), ele);
        }
    });
};
export default mountApps;
