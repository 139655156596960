import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Input from "components/Input/Input";
import InputAddon from "components/InputAddon";

import { formatCurrency } from "helpers/formatCurrency";

import "./_style.scss";
import { Calculator as OptionalAmountCalculator } from "helpers/calculateOptionalAmount";
import TextTranslator from "components/TranslateText/TextTranslator";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const OptionalSelector = ({
  donationValue,
  feeStructure,
  feeValue,
  field,
  optionalTier,
  onOptionalSelectChange,
  setFieldValue,
  initialValue,
}) => {
  const [value, setValue] = useState(0);
  const [initialedValue, setInitialValue] = useState(0);

  const calc = new OptionalAmountCalculator()
    .setFeeStructure(feeStructure);

  const customTier = 4;
  const selectedTier = initialValue
    ? customTier
    : optionalTier === 0
      && calc.calc(donationValue, feeValue, 0)
        === null
      ? 1
      : optionalTier;
  let optionalDonationValue = setFieldValue ? field?.value : value;
  optionalDonationValue = +optionalDonationValue || 0;

  const { translate: t } = useLanguageProvider();

  useEffect(() => {
    if (value !== field.value) {
      setValue(field.value);
    }
    if (initialedValue !== initialValue) {
      setInitialValue(initialedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, initialValue]);

  const handleInputOtherChange = (event) => {
    const val = event.target.value;
    const otherValue = Number.parseFloat(val);
    const updatedValue = isNaN(otherValue) ? 0.0 : otherValue;
    setValue(updatedValue);
    !setFieldValue ? field.onChange(updatedValue) : setFieldValue(updatedValue);
  };

  const handleOptionalSelectUpdate = (event) => {
    const selectedTier = parseInt(event.target.value, 10);

    // Update state with currently selected tier
    onOptionalSelectChange && onOptionalSelectChange(selectedTier);

    // Set the hidden value of the optional amount to Chuffed, based on the selected value of the dropdown
    // If it's the "other" field, default to 0
    let amount = calc.calc(
      donationValue,
      feeValue,
      selectedTier,
    );
    if (selectedTier === 4) {
      amount = parseFloat(value);
    }

    setValue(amount);

    !setFieldValue
      ? field && field.onChange && field.onChange(amount)
      : setFieldValue(amount);
  };

  const optionalAmountHappiness = () => {
    let selectedTier = optionalTier === 0
      && calc.calc(donationValue, feeValue, 0)
        === null
      ? 1
      : optionalTier;

    if (optionalTier === 4) {
      let tier = 0;
      selectedTier = 0;
      // custom value, calculate the happiness based on the relative tier
      const val = parseFloat(value);
      while (
        tier <= 3
        && calc.calc(
          donationValue,
          feeValue,
          tier,
        ) <= val
      ) {
        selectedTier = tier;
        tier++;
      }
    }

    if (selectedTier === 1) {
      return "😀";
    }
    if (selectedTier === 2) {
      return "😄";
    }
    if (selectedTier === 3) {
      return "😍";
    }

    return "🙂";
  };

  return (
    <div className="optional-selector">
      <div className="optional-selector__line-item">
        <span className="optional-selector__line-item-label">
          <TextTranslator>Thanks for tipping Chuffed.org (optional):</TextTranslator>
        </span>
        <span className="optional-selector__line-item-value">
          {formatCurrency(setFieldValue ? field?.value : value, feeStructure)}
        </span>
      </div>
      <div>
        <input type="hidden" name={field.name ?? ""} value={field.value ?? 0} />
        <select
          className="optional-selector__select"
          id="donation-form-optional-tier"
          onBlur={handleOptionalSelectUpdate}
          onChange={handleOptionalSelectUpdate}
          onInput={handleOptionalSelectUpdate}
          value={selectedTier}
        >
          {calc.calc(
            donationValue,
            feeValue,
            0,
            feeStructure,
          ) !== 0 && (
            <option value={0}>
              +
              {formatCurrency(
                calc.calc(
                  donationValue,
                  feeValue,
                  0,
                ),
                feeStructure,
              )}
            </option>
          )}
          <option value={1}>
            +
            {formatCurrency(
              calc.calc(
                donationValue,
                feeValue,
                1,
              ),
              feeStructure,
            )}
          </option>
          <option value={2}>
            +
            {formatCurrency(
              calc.calc(
                donationValue,
                feeValue,
                2,
              ),
              feeStructure,
            )}
          </option>
          <option value={3}>
            +
            {formatCurrency(
              calc.calc(
                donationValue,
                feeValue,
                3,
              ),
              feeStructure,
            )}
          </option>
          <option value={4}>{t("Other")}</option>
        </select>

      </div>
      {!(donationValue <= 0 || isNaN(donationValue)) && selectedTier === 4 && (
        <div className="optional-selector__input-other">
          <InputAddon addOnLeft={feeStructure.currencySymbol}>
            <Input
              min="0"
              onChange={handleInputOtherChange}
              placeholder="Enter Amount"
              step="0.01"
              type="number"
              dataTestId="optional-donation-custom-input"
              value={optionalDonationValue}
            />
          </InputAddon>
        </div>
      )}
      {!(donationValue <= 0 || isNaN(donationValue)) && (
        <div className="optional-selector__about">
          <span className="optional-selector__about-media" data-testid="optional-amount-emoticon">
            {optionalAmountHappiness()}
          </span>
          <span className="optional-selector__about-copy">
            <TextTranslator>
              Your tips help us cover our operating expenses so we can continue to offer a 100%
              free crowdfunding platform to campaigns like this one.
            </TextTranslator>
          </span>
        </div>
      )}
    </div>
  );
};

OptionalSelector.propTypes = {
  donationValue: PropTypes.number,
  feeStructure: PropTypes.object,
  feeValue: PropTypes.number,
  field: PropTypes.object,
  onOptionalSelectChange: PropTypes.func,
  optionalTier: PropTypes.number,
  setFieldValue: PropTypes.func,
  initialValue: PropTypes.number,
};

export default OptionalSelector;
