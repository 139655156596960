import { calculateOptionalAmount } from "helpers/calculateOptionalAmount";
import { calculateProcessingFee } from "helpers/calculateProcessingFee";
import { isEmptyObject } from "helpers/emptyObject";
import { SUBMIT_FAILURE } from "../store/state";

export const handleCardError = (data, handlers, errorType) => {
  const updatedFeeConfirmation = {
    ...data,
    cardBrand: data.cardBrand,
    cardCountry: data.cardCountry,
    domesticRegion: data.domesticRegion,
    domesticRegionCode: data.domesticRegionCode,
    isVisible: true,
    newChuffedAmount: data.newChuffedAmount,
    newFeeAmount: data.newFeeAmount,
    newPaymentMethod: data.newPaymentMethod,
    oldChuffedAmount: data.oldChuffedAmount,
    oldFeeAmount: data.oldFeeAmount,
    oldPaymentMethod: data.oldPaymentMethod,
    type: errorType,
    isSubmittingFromConfirmation: false,
  };

  handlers.setFeeConfirmation(updatedFeeConfirmation);
  handlers.setUpdatedFees(updatedFeeConfirmation);
  handlers.setProcessingSubmit(false);

  return {
    isValid: false,
    updatedFeeConfirmation,
  };
};

// Apply rules based on campaign country
export const eeaList = ["AT", "BE", "CH", "DE", "DK", "ES", "FI", "FR", "GB", "IE", "IT", "LU", "NL", "NO", "PT", "SE"];

const countriesWithIntlRule = [
  "AU",
  "CA",
  ...eeaList,
];

const countriesWithBrandRule = [
  "AU",
  "CA",
  "US",
  ...eeaList,
];

const submitFailure = (error) => ({
  type: SUBMIT_FAILURE,
  error,
});

export const isCardInternational = (eeaList, feeStructure, actualCardCountryCode) => {
  if (!feeStructure || !feeStructure.economicAreaList) {
    // donors should be able to proceed, this would be an error on our side but fees would have to be fixe
    throw Error("Unable to find economic area list to determine card fees");
  }

  return eeaList.includes(actualCardCountryCode) ? feeStructure.economicAreaList.indexOf("EEA") < 0 : feeStructure.economicAreaList.indexOf(actualCardCountryCode) < 0;
};

export const validateToken = async (
  token,
  donationFormData,
  formValues,
  donationFormContext,
  handlers,
  updatedFees,
) => {
  const { feeStructure } = donationFormData;
  const {
    chuffedAmount,
    donationAmount,
    feeAmount,
    paymentMethod,
  } = formValues;

  const { optionalTier, feeConfirmation } = donationFormContext;

  // If not using fees by payment type, token is always valid
  if (!feeStructure.feesByPaymentType) {
    return {
      isValid: true,
    };
  }

  const actualCardCountryCode = token.card.country;
  const actualCardBrand = token.card.brand;
  const campaignCountry = feeStructure.country;
  const campaignCountryCode = feeStructure.countryCode;
  const chosenCardBrand = paymentMethod;
  let selectedTier = optionalTier;
  const isActuallyAmex = chosenCardBrand === "visa-mc" && actualCardBrand === "American Express";
  const newPaymentMethod = isActuallyAmex === true ? "amex" : paymentMethod;
  const isActuallyInternational = isCardInternational(eeaList, feeStructure, actualCardCountryCode);
  const newFeeAmount = calculateProcessingFee(donationAmount, newPaymentMethod, feeStructure, !isActuallyInternational);

  if (
    optionalTier === 0
    && calculateOptionalAmount(
      donationAmount,
      newFeeAmount,
      optionalTier,
      feeStructure,
      chuffedAmount,
    ) === null
  ) {
    selectedTier = 1;
  }
  const newChuffedAmount = Number.parseFloat(
    calculateOptionalAmount(donationAmount, newFeeAmount, selectedTier, feeStructure, chuffedAmount),
  ) || 0;

  const updatedFeeConfirmation = {
    ...feeConfirmation,
    cardBrand: actualCardBrand,
    cardCountry: actualCardCountryCode,
    domesticRegion: campaignCountry,
    domesticRegionCode: campaignCountryCode,
    isVisible: true,
    newChuffedAmount,
    newFeeAmount,
    newPaymentMethod,
    oldChuffedAmount: chuffedAmount,
    oldFeeAmount: feeAmount,
    oldPaymentMethod: paymentMethod,
    isSubmittingFromConfirmation: false,
  };

  if (!updatedFees) {
    if (countriesWithBrandRule.indexOf(campaignCountryCode) >= 0 && isActuallyAmex === true) {
      return handleCardError(updatedFeeConfirmation, handlers, "CARD_BRAND");
    }

    if (countriesWithIntlRule.indexOf(campaignCountryCode) >= 0 && isActuallyInternational === true) {
      return handleCardError(updatedFeeConfirmation, handlers, "COUNTRY");
    }
  }

  handlers.setProcessingSubmit(true);

  return {
    isValid: true,
    updatedFeeConfirmation,
  };
};

export const submitPaymentElementPaymentIntent = async (
  {
    cardErrorHandler,
    dispatch,
    firstName,
    lastName,
    email,
    elements,
    paymentIntentsId,
    returnUrl,
    selectedPaymentMethodType,
    stripe,
    address,
    donationFormHasAddress,
  },
) => {
  if (!paymentIntentsId) {
    return;
  }

  if (selectedPaymentMethodType === "giropay") {
    await stripe.confirmGiropayPayment(
      paymentIntentsId,
      {
        payment_method: {
          billing_details: {
            name: `${firstName} ${lastName}`,
          },
        },
        return_url: returnUrl,
      },
    ).then((result) => {
      if (result.error) {
        cardErrorHandler(result.error.message);
      } else {
        window.location = returnUrl;
      }
    });
  }

  const billingDetails = {
    name: `${firstName} ${lastName}`,
    email: `${email}`,
  };

  if (donationFormHasAddress) {
    billingDetails.address = address;
  }

  await stripe.confirmPayment({
    elements,
    confirmParams: {
      payment_method_data: {
        billing_details: billingDetails,
      },
      return_url: returnUrl,
    },
    redirect: "if_required",
  }).then((result) => {
    if (result.error) {
      cardErrorHandler(result.error.message);
      dispatch(submitFailure(result.error.message));
    } else {
      window.location = returnUrl;
    }
  })
    .catch((error) => {
      const errorMessage = processStripePaymentIntentError(error.message);
      cardErrorHandler(errorMessage);
      dispatch(submitFailure(errorMessage));
    });
};

export const submitPaymentIntent = (
  data,
  redirectUrl,
  closeFeeConfirmation,
  paymentMethod,
  complete,
  stripe,
  cardElem,
  cardErrorHandler,
) => {
  const { paymentIntentsId } = data;
  if (!paymentIntentsId) {
    return;
  }
  stripe.confirmCardPayment(paymentIntentsId, {
    payment_method: {
      card: cardElem,
      billing_details: {
        name: `${data.firstName} ${data.lastName}`,
      },
    },
  }).then((result) => {
    if (result.error) {
      cardErrorHandler(result.error.message);
    } else {
      window.location = redirectUrl;
    }
  });
};

export const confirmPaymentIntent = (
  data,
  redirectUrl,
  closeFeeConfirmation,
  paymentMethod,
  paymentIntentComplete,
  stripeMethods,
) => {
  const {
    paymentIntentsId,
    submitPaymentIntentAction,
  } = data;

  const stripe = stripeMethods;

  if (paymentIntentsId) {
    stripe
      .confirmPaymentIntent(paymentIntentsId, {
        payment_method: paymentMethod.id,
      })
      .then((confirmResult) => {
        if (confirmResult.error) {
          paymentIntentComplete("fail");
        } else {
          paymentIntentComplete("success");

          stripe.handleCardPayment(paymentIntentsId).then((result) => {
            if (result.error) {
              paymentIntentComplete("fail");
              submitPaymentIntentAction(result).catch(() => {
                closeFeeConfirmation();
              });
            } else {
              window.location = redirectUrl;
            }
          });
        }
      });
  }
};

export const handleFeeConfirmationEdit = (e, handlers, feeConfirmation, setFieldValue) => {
  e.preventDefault();
  const {
    updateFeeAmounts,
    closeFeeConfirmation,
  } = handlers;

  updateFeeAmounts(false, feeConfirmation, setFieldValue);
  closeFeeConfirmation(handlers);
  handlers.setUpdatedFees(null);
};

export const handleFeeConfirmationSubmit = (e, handlers, feeConfirmation, donationFormContext) => {
  // stop form from submitting again
  e.preventDefault();
  const { isPaymentRequestMethod } = donationFormContext;

  handlers.setFeeConfirmation({
    ...feeConfirmation,
    isSubmittingFromConfirmation: true,
  });

  afterSubmissionForceClick(isPaymentRequestMethod);
};

export const afterSubmissionForceClick = (isPaymentRequestMethod) => {
  if (isPaymentRequestMethod === true) {
    document.getElementById("js-donate-action-payrequest").click();
  } else {
    document.getElementById("js-donate-action-stripe").click();
  }
};

export const handlePayPalSubmission = (values, donationFormData, donationFormContext, handlers, setFieldValue) => {
  setFieldValue("paymentMethod", "paypal");
  handlers.submitDonation({
    ...values,
    ...donationFormData,
  });
};

export const handleDirectDebitPayment = (event, formProps, handlers) => {
  const {
    values: {
      firstName,
      lastName,
      email,
      chuffedAmount,
      donationAmount,
      feeAmount,
      frequency,
      recipientId,
      isOptIn,
      isOptInChuffed,
    },
  } = formProps;

  const {
    submitDirectDebitAction,
  } = handlers;

  const formData = {
    firstName,
    lastName,
    email,
    chuffedAmount,
    donationAmount,
    feeAmount,
    frequency,
    recipientId,
    isOptIn,
    isOptInChuffed,
  };

  formProps.validateForm().then((errors) => {
    if (isEmptyObject(errors)) {
      formProps.setFieldTouched("cardField", false);
      event.preventDefault();
      submitDirectDebitAction(formData);
    }
  });
};

export const stripeRedirectToCheckout = ({ ...props }) => {
  const {
    stripe,
    directDebitCheckoutSession,
  } = props;

  stripe.redirectToCheckout({
    sessionId: directDebitCheckoutSession,
  });
};

const processStripePaymentIntentError = (errorMessage) => {
  if (errorMessage.includes("billing_details.address")) {
    return "Address is required for Direct Debit. Please complete your address details and try again.";
  }

  return errorMessage;
};
