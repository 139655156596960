import React from "react";

import LinkButton from "components/LinkButton";

import { formatCurrency } from "helpers/formatCurrency";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { shouldDisplayPaymentMethod } from "widgets/Donation/utils/helpers";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createPaypalDonation = (data, i, formProps, handlers, donationFormContext) => {
  const {
    values: { paymentMethod, frequency },
    setFieldValue,
  } = formProps;
  const {
    recipient: { camp_status },
    feeStructure,
  } = data;
  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  const {
    processingSubmit,
    donation: {
      isSubmitting,
      isError,
      errorMessage,
    },
  } = donationFormContext;

  return (
    shouldDisplayPaymentMethod("paypal", { paymentMethod, frequency, data })
      ? (
        <div className="donation-form__payment-method" data-test="donation-form__paypal">
          <LinkButton
            id="js-donate-action-paypal"
            isBold
            isDisabled={camp_status === "draft" || isSubmitting}
            isFullWidth
            isLoading={isSubmitting || (processingSubmit && paymentMethod === "paypal")}
            loadingMessage={<TextTranslator>Submitting payment...</TextTranslator>}
            look="paypal"
            type="submit"
            onClick={() => {
              setFieldValue("submitButton", "paypal");
            }}
            size="large"
          >
            <TextTranslator replacements={
              {
                currency: formatCurrency(donationTotal, feeStructure),
              }
            }
            >
              Pay :currency via PayPal
            </TextTranslator>
          </LinkButton>
          {
          (paymentMethod === "paypal" && isError)
          && (
          <div className="donation-form__error">
            { errorMessage }
          </div>
          )
        }
        </div>
      )
      : null
  );
};
