import React from "react";
import LinkButton from "components/LinkButton";

import { formatCurrency } from "helpers/formatCurrency";
import { frequencyLabel } from "widgets/Donation/utils/regularGivingHelpers";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createDirectDebitDonation = (donationFormData, i, formProps, handlers, donationFormContext) => {
  const donationTotal = calculateDonationTotal(donationFormData.feeStructure, formProps);
  const { handleDirectDebitPayment } = handlers;
  const regularGivingContext = {
    ...donationFormData,
    values: {
      ...formProps.values,
    },
  };

  if (formProps.values.frequency === "one-off") {
    return;
  }

  return (
    <div data-testid="direct-debit-submission" className="donation-form__payment-method donation-form__payment-method--direct-debit">
      <LinkButton
        id="js-donate-action-payrequest"
        isBold
        isDisabled={
          donationFormContext?.draft
          || (donationFormContext?.donation?.isSubmitting || donationFormContext?.processingSubmit)
          && donationFormContext?.isPaymentRequestMethod === false
        }
        isFullWidth
        isLoading={
          (donationFormContext?.donation?.isSubmitting || donationFormContext?.processingSubmit)
          && donationFormContext?.isPaymentRequestMethod === true
        }
        loadingMessage={<TextTranslator>Submitting payment...</TextTranslator>}
        look="primary"
        onClick={(e) => handleDirectDebitPayment(e, formProps, handlers, donationFormContext)}
        size="large"
        data-testid="donation-form-payment-method-direct-debit"
      >
        <TextTranslator replacements={
          {
            currency: formatCurrency(donationTotal, donationFormData?.feeStructure),
            frequency: frequencyLabel(true, false, regularGivingContext),
          }
        }
        >
          Pay :currency:frequency via Direct Debit
        </TextTranslator>
      </LinkButton>
    </div>
  );
};
