import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";

import "./_style.scss";

class CustomSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    defaultOption: PropTypes.string,
    groups: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isSelected: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        name: PropTypes.string,
        group: PropTypes.string,
        disabled: PropTypes.bool,
      }).isRequired,
    ).isRequired,
    selectDefault: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    withImage: PropTypes.bool,
  };

  static defaultProps = {
    options: [],
    groups: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value ? props.value.toString() : null,
    };
  }

  selectChange = (e) => {
    this.setState({
      value: e.target.value,
    });

    if (typeof this.props.onChange === "function") {
      this.props.onChange(e);
    }
  };

  selectBlur = (e) => {
    this.setState({
      value: e.target.value,
    });

    if (typeof this.props.onBlur === "function") {
      this.props.onBlur(e);
    }
  };

  groupOptions = () => {
    const options = [{
      name: null,
      options: [],
    }];
    const groups = [null];

    this.props.options.forEach((option) => {
      const group = option.group === undefined || option.group === null ? null : option.group;
      if (groups.indexOf(group) === -1) {
        groups.push(group);
        options.push({
          name: group,
          options: [],
        });
      }
      options[groups.indexOf(group)].options.push(option);
    });

    return options;
  };

  renderOptionGroup = (optionGroup) => {
    if (optionGroup.name === null) {
      return (
        optionGroup.options.map((option, key) => (
          <option
            key={option.name ?? key}
            value={option.value.toString()}
            disabled={option.disabled}
            selected={option.selected}
            dangerouslySetInnerHTML={{ __html: option.text }}
          />
        ))
      );
    }
    return (
      <optgroup label={optionGroup.name}>
        {
            optionGroup.options.map((option) => (
              <option
                key={option.name}
                value={option.value.toString()}
                disabled={option.disabled}
                selected={option.selected}
                dangerouslySetInnerHTML={{ __html: option.text }}
              />
            ))
          }
      </optgroup>
    );
  };

  render() {
    return (
      <label
        className={
          classNames(
            "select",
            this.props.className,
            {
              "--selected": this.props.isSelected,
            },
          )
        }
        htmlFor={this.props.name}
      >
        <select
          id={this.props.name}
          name={this.props.name}
          onClick={this.selectBlur}
          onChange={this.selectChange}
          defaultValue={this.props.value}
          disabled={this.props.disabled || false}
          className={
                classNames(
                  "select__element",
                  {
                    "--selected": this.props.isSelected,
                    "--isInvalid": this.props.isInvalid,
                  },
                )
              }
        >
          {
                this.props.defaultOption
                  ? <option value="">{this.props.defaultOption}</option>
                  : null
              }
          {
                this.groupOptions().map((optionGroup) => (
                  this.renderOptionGroup(optionGroup)
                ))
              }
        </select>
      </label>
    );
  }
}

export default CustomSelect;
