import React from "react";

import { formatCurrency, matchAmount } from "helpers/formatCurrency";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createMatchedGiving = (donationFormData, i, formProps) => {
  const { feeStructure } = donationFormData;
  const {
    values: {
      donationAmount,
    },
  } = formProps;

  const anonymousMatcher = <TextTranslator>Your donation is being doubled by donors!</TextTranslator>;
  const namedMatcher = (
    <span>
      <TextTranslator>Your donation is being doubled by</TextTranslator>
      {" "}
      {donationFormData.matchedGivingMatchers}
    </span>
  );
  return (
    <div className="donation-form__matched-giving optional-selector" data-test="donation-form__matched-giving" key={i}>
      <span className="optional-selector__line-item option-selector__line-item-title">
        {
          donationFormData.matchedGivingMatchers && donationFormData.matchedGivingMatchers !== "Anonymous"
            ? namedMatcher
            : anonymousMatcher
        }
      </span>
      <div className="donation-form__matched-giving-amount donation-form__matched-giving-amount-copy optional-selector__about-copy">
        <span><TextTranslator>Campaign Receives:</TextTranslator></span>
        <span className="optional-selector__line-item-value">
          { matchAmount(formatCurrency(donationAmount, feeStructure), feeStructure) }
        </span>
      </div>
    </div>
  );
};
