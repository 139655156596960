import React from "react";

import { formatCurrency } from "helpers/formatCurrency";
import DonationFeeConfirmation from "widgets/Donation/components/FeeConfirmation/DonationFeeConfirmation";

import { frequencyLabel } from "widgets/Donation/utils/regularGivingHelpers";
import { calculatePlatformFeeTotal } from "widgets/Donation/utils/donationCalculatorHelpers";

import { handleFeeConfirmationEdit, handleFeeConfirmationSubmit } from "widgets/Donation/utils/paymentSubmissionHelpers";

export const renderFeeConfirmation = (
  donationFormData,
  formValues,
  updatedFeeConfirmation,
  donationFormContext,
  handlers,
  setFieldValue,
) => {
  const { feeStructure } = donationFormData;
  const { donationAmount } = formValues;
  const {
    newChuffedAmount,
    newFeeAmount,
  } = updatedFeeConfirmation;

  const isConditionalDecimal = true;
  const donationAmountText = formatCurrency(donationAmount, feeStructure);
  const feeAmountText = formatCurrency(newFeeAmount, feeStructure);
  const chuffedAmountText = formatCurrency(newChuffedAmount, feeStructure);
  const platformFeeAmountText = formatCurrency(calculatePlatformFeeTotal(formValues), feeStructure, true);
  const donationValue = Number.parseFloat(donationAmount, 10);
  const newFeeValue = Number.parseFloat(newFeeAmount, 10);
  const newChuffedValueOrDefault = (Number.parseFloat(newChuffedAmount, 10) || 0);
  let totalAmount = donationValue + newFeeValue + newChuffedValueOrDefault;

  if (!feeStructure.optionalPlatformDonations) {
    totalAmount = donationValue;
  }

  const totalAmountText = formatCurrency(totalAmount, feeStructure, isConditionalDecimal);

  const regularGivingContext = {
    ...donationFormData,
    values: {
      ...formValues,
    },
  };

  return (
    <DonationFeeConfirmation
      chuffedAmount={chuffedAmountText}
      donationAmount={donationAmountText}
      feeAmount={feeAmountText}
      feeStructure={feeStructure}
      frequencyLong={frequencyLabel(false, true, regularGivingContext)}
      frequencyShort={frequencyLabel(true, false, regularGivingContext)}
      isSubmitting={donationFormContext.isSubmitting || donationFormContext.processingSubmit}
      onEdit={(e) => handleFeeConfirmationEdit(e, handlers, updatedFeeConfirmation, setFieldValue)}
      onPay={(e) => handleFeeConfirmationSubmit(e, handlers, updatedFeeConfirmation, donationFormContext)}
      platformFeeAmount={platformFeeAmountText}
      settings={updatedFeeConfirmation}
      totalAmount={totalAmountText}
    />
  );
};
