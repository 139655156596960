import React, { Component } from "react";
import PropTypes from "prop-types";
import "./_style.scss";

class ImpactSelector extends Component {
  static propTypes = {
    currencySymbol: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    impactLevels: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })).isRequired,
    handleImpactChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func,
  };

  state = {
    selectedIndex: null,
  };

  handleChange = (event) => {
    this.setState({
      selectedIndex: Number.parseInt(event.target.getAttribute("data-index"), 10),
    });

    document.getElementById("impact-selector-option-other").value = "";

    const selectedAmount = Number.parseFloat(event.target.value);

    // Trigger update of optional value to the redux-form field
    if (!this.props.setFieldValue) {
      this.props.field.onChange(selectedAmount, this.props.handleImpactChange(selectedAmount));
    } else {
      this.props.setFieldValue("donationAmount", selectedAmount);
      this.props.handleImpactChange(selectedAmount);
    }
  };

  handleOtherChange = (event) => {
    this.setState({
      selectedIndex: -1,
    });

    const otherValue = Number.parseFloat(event.target.value);
    const otherValueParsed = isNaN(otherValue) ? 0.00 : otherValue.toFixed(2);

    // Trigger update of value to the redux-form field
    if (!this.props.setFieldValue) {
      this.props.field.onChange(otherValueParsed, this.props.handleImpactChange(otherValueParsed));
    } else {
      this.props.setFieldValue("donationAmount", otherValueParsed);
      this.props.handleImpactChange(otherValueParsed);
    }
  };

  handleOtherFocus = (event) => {
    this.setState({
      selectedIndex: -1,
    });

    const selectedAmount = Number.parseFloat(event.target.value);

    // Trigger update of value to the redux-form field
    if (!this.props.setFieldValue) {
      this.props.field.onChange(selectedAmount, this.props.handleImpactChange(0));
    } else {
      this.props.setFieldValue("donationAmount", selectedAmount);
      this.props.handleImpactChange(0);
    }
  };

  render() {
    const {
      currencySymbol,
      impactLevels,
      ...other
    } = this.props;

    const otherActiveClass = this.state.selectedIndex === -1 ? " --active" : "";

    return (
      <div className="impact-selector" data-test="impact-selector">
        <input type="hidden" {...other} />
        <div className="impact-selector__options">
          {
            impactLevels.map((impact, index) => {
              const optionBaseClass = "impact-selector__option";
              const optionClass = this.state.selectedIndex === index ? `${optionBaseClass} --active` : optionBaseClass;

              return (
                <label
                  className={optionClass}
                  htmlFor={`impact-selector-option${index}`}
                  key={index}
                  data-test="impact-selector-item"
                >
                  <span className="impact-selector__option-amount">
                    {currencySymbol}
                    {impact.amount}
                  </span>
                  <span className="impact-selector__option-title">
                    {impact.title}
                  </span>
                  <input
                    className="impact-selector__option-input"
                    data-index={index}
                    data-test="impact-selector-option"
                    id={`impact-selector-option${index}`}
                    name="impact-amount"
                    onChange={this.handleChange}
                    type="radio"
                    value={impact.amount}
                  />
                </label>
              );
            })
          }
        </div>
        <div className="impact-selector__other">
          <label className={`impact-selector__option-other${otherActiveClass}`} htmlFor="impact-selector-option-other">
            <span className="impact-selector__input-currency">{currencySymbol}</span>
            <input
              className="impact-selector__input-other"
              id="impact-selector-option-other"
              min="0"
              onChange={this.handleOtherChange}
              onFocus={this.handleOtherFocus}
              placeholder="Other amount"
              type="number"
              data-test="impact-selector-option-other"
            />
          </label>
        </div>
      </div>
    );
  }
}

export default ImpactSelector;
