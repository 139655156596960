import React from "react";

import DonationCustomFields from "widgets/Donation/components/CustomFields/donationCustomFields";
import { enabledCustomFields } from "widgets/Donation/utils/getInitialValues";

export const hasValidDonationFormCustomFields = (
  donationFormCustomFields,
) => donationFormCustomFields !== undefined
  && donationFormCustomFields !== null
  && donationFormCustomFields.length > 0;

export const formatDonationCustomDonorDetails = (
  donationFormFields,
) => donationFormFields.reduce((obj, item, index) => {
  obj[`customField${index}`] = item;
  return obj;
}, {});

export const createCustomQuestions = (donationFormData, i, formProps) => {
  let customFormFields = [];

  if (hasValidDonationFormCustomFields(donationFormData?.donationFormCustomFields)) {
    customFormFields = enabledCustomFields(donationFormData.donationFormCustomFields);
  }

  if (customFormFields.length === 0) {
    return null;
  }
  return (
    <DonationCustomFields
      formFields={formatDonationCustomDonorDetails(customFormFields)}
      questions={customFormFields}
      formProps={formProps}
    />
  );
};
