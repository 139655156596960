import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ErrorDisplay from 'components/ErrorDisplay';
import Input from 'components/Input';
import InputGroup from 'components/InputGroup';
import CustomSelect from 'components/CustomSelect';
import auRegions from 'data/country-australia-regions.json';
import usRegions from 'data/country-united-states-regions.json';
import { fieldRequired } from 'widgets/Donation/utils/fieldHelpers';
import { generateAURegionOptions, generateUSRegionOptions } from '../helpers';
const AddressRegion = ({ donationFormFields, donorFields, handleRegionChange, }) => {
    const { values: { addressCountry, addressRegion, }, setFieldTouched, touched, errors, } = donorFields;
    if (addressCountry === undefined || addressCountry === '') {
        return null;
    }
    const reqAddress = fieldRequired(donationFormFields.address.status) ? '*' : '';
    const auRegionOptions = generateAURegionOptions(auRegions);
    const usRegionOptions = generateUSRegionOptions(usRegions);
    let regionLabel = '';
    let regionPlaceholder = '';
    switch (addressCountry) {
        case 'Australia':
        case 'United States':
            regionLabel = 'State';
            break;
        case 'United Kingdom':
            regionLabel = 'County';
            regionPlaceholder = 'Greater London';
            break;
        default:
            regionLabel = 'State / Province / Region';
            regionPlaceholder = 'Greater London';
    }
    return (_jsxs(InputGroup, { label: `${regionLabel}${reqAddress}`, look: "sentence-case", spacing: "none", v2: true, className: undefined, description: undefined, inline: undefined, inlineAlign: undefined, inlineWidth: undefined, isFlashHighlight: undefined, size: undefined, marginBottom: undefined, children: [(addressCountry === 'Australia')
                && (_jsx(ErrorDisplay, { alwaysShowErrors: ((touched === null || touched === void 0 ? void 0 : touched.addressRegion) && ((errors === null || errors === void 0 ? void 0 : errors.addressRegion) !== null)), customMsg: errors === null || errors === void 0 ? void 0 : errors.addressRegion, field: addressRegion, errorKey: undefined, children: _jsx(CustomSelect, { name: "addressRegion", autoComplete: "shipping address-level1", onChange: handleRegionChange, value: addressRegion, onBlur: () => setFieldTouched('addressRegion', true), options: auRegionOptions }) })), (addressCountry === 'United States')
                && (_jsx(ErrorDisplay, { alwaysShowErrors: ((touched === null || touched === void 0 ? void 0 : touched.addressRegion) && ((errors === null || errors === void 0 ? void 0 : errors.addressRegion) !== null)), customMsg: errors === null || errors === void 0 ? void 0 : errors.addressRegion, field: addressRegion, errorKey: undefined, children: _jsx(CustomSelect, { name: "addressRegion", autoComplete: "shipping address-level1", onChange: handleRegionChange, value: addressRegion, onBlur: () => setFieldTouched('addressRegion', true), options: usRegionOptions }) })), (addressCountry !== 'Australia'
                && addressCountry !== 'United States')
                && (_jsx(ErrorDisplay, { alwaysShowErrors: ((touched === null || touched === void 0 ? void 0 : touched.addressRegion) && ((errors === null || errors === void 0 ? void 0 : errors.addressRegion) !== null)), customMsg: errors === null || errors === void 0 ? void 0 : errors.addressRegion, field: addressRegion, errorKey: undefined, children: _jsx(Input, { name: "addressRegion", autoComplete: "shipping address-level1", onChange: handleRegionChange, maxLength: 255, value: addressRegion, placeholder: regionPlaceholder }) }))] }));
};
export default AddressRegion;
