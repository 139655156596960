import React from "react";

const normalizeValidationMessages = [
  {
    message: "The input.slug has already been taken.",
    updated: "The URL must be unique",
  },
  {
    message: "The input.password must be at least 6 characters.",
    updated: "The password must be at least 6 characters",
  },
  {
    message: "The input.email must be a valid email address.",
    updated: "The email must be a valid email address",
  },
];

export const normalizeErrorMessages = [
  {
    type: "rate limited",
    resolve: () => <p>Unfortunately you have tried too many times. Please come back at a later time.</p>,
  },
  {
    type: "unauthorized",
    resolve: () => <p>Unauthorized to perform this action</p>,
  },
  {
    type: "missing variables",
    resolve: (variables) => (
      <div>
        <p>Invalid data, please ensure these are entered:</p>
        {
                variables.map((variable, i) => (
                  <p key={i}>
                    <span className="error-handler__index">
                      {++i}
                      .
                    </span>
                    {variable.rules}
                  </p>
                ))
            }
      </div>
    ),
  },
  {
    type: "missing input",
    resolve: () => <p>Something went wrong, please refresh the page and try again.</p>,
  },
  {
    type: "validation",
    resolve: (validationRules) => validationRules.map((validation, i) => (
      <p key={i}>
        {
          normalizeValidationMessages
            .find((message) => message.message === validation.message)?.updated
            || validation.message
        }
      </p>
    )),
  },
];

export const normalizeNetworkErrors = [
  {
    type: 401,
    resolve: () => <p>Please log in and try again.</p>,
  },
  {
    type: 400,
    resolve: () => <p>Something went wrong. Please refresh the page.</p>,
  },
  {
    type: 403,
    resolve: () => <p>Something went wrong, please try again.</p>,
  },
  {
    type: 404,
    resolve: () => <p>Unfortunately we were unable to find what you were looking for. Please try again.</p>,
  },
  {
    type: 500,
    resolve: () => <p>Something went wrong. Please refresh the page.</p>,
  },
  {
    type: 502,
    resolve: () => <p>Something went wrong. Please refresh the page.</p>,
  },
  {
    type: 503,
    resolve: () => <p>Something went wrong. Please come back later.</p>,
  },
  {
    type: 504,
    resolve: () => <p>Unfortunately request has taken too long and timed out.</p>,
  },
];

export const getErrorDisplay = (errorType, normalizeErrorMessages, errorData) => {
  const errorDisplay = normalizeErrorMessages.find((error) => error.type === errorType);
  return errorDisplay ? errorDisplay.resolve(errorData) : "Something went wrong. Please try again";
};
