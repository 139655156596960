import { formatCurrency } from "helpers/formatCurrency";
import React from "react";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { frequencyLabel } from "widgets/Donation/utils/regularGivingHelpers";

import TextTranslator from "components/TranslateText/TextTranslator";
import "./_style.scss";

export const createDonationTotal = (
  feeStructure,
  openCurrencyModal,
  formProps,
  regularGivingContext,
  shouldDisplayChangeCurrency = true,
) => {
  const { currency } = feeStructure ?? {};
  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  const hasFrequencyLabel = frequencyLabel(false, true, regularGivingContext);

  return (
    <div className="donation-form__line-item --total donation-total__container">
      <span>
        <span
          className="donation-form__line-item-label --total-label donation-total__change-currency"
          data-testid="donation-total-amount-currency"
        >
          <TextTranslator
            replacements={{ total: frequencyLabel(false, true, regularGivingContext) ?? "" }}
            children="Total amount:total in"
          />
          {" "}
          <span>
            &nbsp;
            {currency.toUpperCase()}
          </span>
          :
        </span>
        {
          shouldDisplayChangeCurrency
          && (
          <span className="donation-form__line-item-label --change-currency donation-form__currency-conversion">
            <a
              className="donation-form__line-item--currency-change"
              onClick={openCurrencyModal}
              data-testid="donation-change-currency"
            >
              <TextTranslator>Change currency</TextTranslator>
            </a>
          </span>
          )
        }
      </span>
      <span className="donation-form__line-item-value" data-testid="donation-form-total">
        <span className="donation-form__line-item-total">
          {
            formatCurrency(donationTotal, feeStructure)
          }
        </span>
        {
            hasFrequencyLabel.length > 0
            && (
            <span className="donation-form__line-item-suffix">
              {
                frequencyLabel(true, true, regularGivingContext)
              }
            </span>
            )
          }
      </span>
    </div>
  );
};
