export const hasPaymentPrefill = (params) => {
  const urlParams = new URLSearchParams(params);

  if (urlParams.has("retry")) {
    return {
      token: urlParams.get("retry"),
      email: urlParams.get("email"),
    };
  }

  return false;
};
