import { shouldShowFrequency } from "widgets/Donation/utils/regularGivingHelpers";
import { hasPaypal, hasStripe } from "widgets/Donation/utils/helpers";
import { PaymentMethodTypes } from "widgets/Donation/types";

export const getCardOptions = (paymentMethodOptions, feeStructure) => {
  const {
    feeDomesticVariable,
    currencySymbol,
    feeDomesticFixed,
    feeDomesticVariableAmex,
  } = feeStructure;

  paymentMethodOptions.push({
    fee: `${(feeDomesticVariable * 100).toFixed(1)}% + ${currencySymbol}${feeDomesticFixed.toFixed(2)}`,
    name: "paymentMethod",
    text: "Visa/MasterCard",
    value: "visa-mc",
  });

  paymentMethodOptions.push({
    fee: `${(feeDomesticVariableAmex * 100).toFixed(1)}% + ${currencySymbol}${feeDomesticFixed.toFixed(2)}`,
    name: "paymentMethod",
    text: "Amex",
    value: "amex",
  });
};

const getPaypalOption = (paymentMethodOptions, feeStructure) => {
  const {
    feePayPalVariable,
    currencySymbol,
    feePayPalFixed,
  } = feeStructure;

  paymentMethodOptions.push({
    fee: `${(feePayPalVariable * 100).toFixed(1)}% + ${currencySymbol}${feePayPalFixed.toFixed(2)}`,
    name: "paymentMethod",
    text: "PayPal",
    value: "paypal",
  });
};

export const getPaymentMethodOptions = ({ ...props }, frequency) => {
  const {
    perk,
    regularGiving,
    feeStructure,
    isDonationUpdate,
  } = props.data;

  const {
    donation: {
      paymentIntentsId,
    },
  } = props.donationFormContext;

  const paymentMethodOptions = [];

  const isStripeAndValidPaymentIntent = hasStripe(props.data) && paymentIntentsId;

  if (isStripeAndValidPaymentIntent || isDonationUpdate) {
    getCardOptions(paymentMethodOptions, feeStructure);
  }

  if (hasPaypal(props.data) && (shouldShowFrequency(regularGiving, perk) === false || frequency === "one-off")) {
    getPaypalOption(paymentMethodOptions, feeStructure);
  }

  return paymentMethodOptions;
};

export const getPaymentMethodTypes = ({ financialInformation: { paymentMethods } }) =>
  paymentMethods.find((pm) => pm.key === "stripeau")?.paymentMethod.availableMethods || [];

export const hasBacsPaymentMethod = (formData) => {
  if (formData.feeStructure?.currency?.toLowerCase() !== "gbp") {
    return false;
  }

  const paymentMethodTypes = getPaymentMethodTypes(formData);

  return paymentMethodTypes.some((method) => method.toLowerCase() === PaymentMethodTypes.Bacs);
};
