import React from "react";

import ErrorDisplay from "components/ErrorDisplay";
import Select from "react-select";
import TextTranslator from "components/TranslateText/TextTranslator";

import {
  customStyles,
  generateTargetDefault,
  generateOptions,
} from "./helpers";

import "./_style.scss";

export const createFundraisersDropdown = (donationFormData, i, formProps, handlers, donationFormContext) => {
  const { fundraiserChange } = handlers;
  const targetRecipientId = donationFormContext?.target?.id || donationFormData.target || donationFormData.recipient;
  const fundraiserDropdownOptions = generateOptions(donationFormData, donationFormContext);
  const placeHolderText = <TextTranslator children="Select campaign or fundraiser" />;

  const hasTarget = ((formProps.touched.targetId && formProps.errors.targetId) ?? "").length > 0;
  return (
    <div className="donation-form__fieldset">
      <div className="donation-form__fundraiser-dropdown" data-test="fundraiser-dropdown">
        <ErrorDisplay
          alwaysShowErrors={hasTarget}
          customMsg={formProps.errors.targetId}
        >
          <Select
            styles={customStyles}
            name="targetId"
            onChange={(e) => fundraiserChange(e, donationFormData, handlers, formProps)}
            onBlur={() => formProps.setFieldTouched("targetId", true)}
            options={fundraiserDropdownOptions}
            defaultValue={generateTargetDefault({
              formProps, targetRecipientId, fundraiserDropdownOptions, donationFormContext, donationFormData,
            })}
            isSearchable={false}
            placeholder={placeHolderText}
            id="fundraiser-dropdown"
          />
        </ErrorDisplay>
      </div>
    </div>
  );
};
