import React from "react";

export const createCampaignCardWrapper = (donationFormData, i, formProps, handlers, donationFormContext) => {
  const { target } = donationFormContext;

  if (!target) {
    return;
  }

  return (
    <div className="payment__campaign" data-test="campaign-card">
      <div className="payment__campaign-card --v2 --mobile">
        <div className="payment__campaign-mobile-title">
          <p>{target.title}</p>
        </div>
        <div className="payment__campaign-image">
          <a
            className="payment__image --hasLoaded"
            href={(target.id !== Number(donationFormData.recipientId) ? "/fundraiser/" : "/project/") + target.slug}
            style={{ backgroundImage: `url('${target.camp_image}')` }}
          >
            <img className="payment__image-tag" src={target.image} alt="" />
            {
              target.user_image !== null && <img className="payment__fundraiser-profile-image" src={target.user_image} alt="" />
            }
          </a>
        </div>
      </div>
    </div>
  );
};
