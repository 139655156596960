import retrieveUTM from "utils/UTM";
import { formatDonationDonorDetails } from "widgets/Donation/components/Details/renderDonorDetails";
import { fieldIsEnabled } from "widgets/Donation/utils/fieldHelpers";
import { enabledCustomFields } from "widgets/Donation/utils/getInitialValues";
import {
  shouldAssignDonationToChallenger,
} from "widgets/Donation/utils/shouldAssignDonationToChallenger";

export const generateRequestData = (data, type) => {
  let chuffedAmount = Number.parseFloat(data.chuffedAmount) || 0;
  const donationAmount = Number.parseFloat(data.donationAmount);

  // If the campaign is using a Flat Fee structure, then add the Chuffed flat fee amount to the chuffedAmount
  if (data.financialInformation.paymentModel.type === "FlatFee" && window.Chuffed) {
    chuffedAmount += donationAmount * window.Chuffed.donationInit.flatFeeRate;
  }

  const directDebitGatewayAmount = type === "direct_debit" && data.donationAmount > 180
    ? 2.0
    : (data.donationAmount + chuffedAmount) / 100 + 0.20;
  const directDebitFees = type === "direct_debit"
    ? (data.donationAmount + chuffedAmount) / 100 + (0.20 + chuffedAmount)
    : null;

  const feeAmount = Number.parseFloat(directDebitFees !== null ? directDebitGatewayAmount : data.feeAmount);

  const mappedDonationData = formatDonationData(data);

  const {
    optionalPlatformDonations,
    feesByPaymentType,
    donationFormFields,
    requiresOptIn,
    currency,
    directDebitCustomer,
    directDebitCheckoutSession,
    giftAidEnabled,
    donationFormCustomFields,
  } = mappedDonationData;

  const formattedDonationFields = formatDonationDonorDetails(donationFormFields);
  const collectingAddress = formattedDonationFields && fieldIsEnabled(formattedDonationFields.address?.status);
  const collectingPhone = formattedDonationFields && fieldIsEnabled(formattedDonationFields.phone?.status);
  const collectingTitle = formattedDonationFields && fieldIsEnabled(formattedDonationFields.title?.status);
  const mixpanel_id = (window.mixpanel && window.mixpanel.get_distinct_id) ? window.mixpanel.get_distinct_id() : null;

  const shouldAssignToChallenger = shouldAssignDonationToChallenger(data);

  const formData = {
    paymentIntent: type === "update_payment_intent" ? data.paymentIntentSecret : null,
    hideme: (data.isShowName === false) ? "nothingshown" : "namedonation",
    keep_updated: data.isOptIn,
    keep_updated_with_chuffed: data.isOptInChuffed,
    requires_opt_in: requiresOptIn,
    campaign_id: Number(data.recipientId),
    recipient_type: shouldAssignToChallenger.isChallenger ? shouldAssignToChallenger.recipient_type : "campaign",
    recipient_id: shouldAssignToChallenger.recipient_id,
    mixpanel_id,
    donation: donationAmount,
    chipin_amount: data.newChuffedAmount
      ? (Number(data.newChuffedAmount) + Number(data.newFeeAmount))
      : (chuffedAmount + feeAmount),
    gateway_fee_amount: data.newFeeAmount ? data.newFeeAmount : feeAmount,
    application_fee: data.newFeeAmount
      ? (Number(data.newChuffedAmount) + Number(data.newFeeAmount))
      : (chuffedAmount + feeAmount),
    item_donation: calculateDonationTotal(
      donationAmount,
      (data.newFeeAmount ? Number(data.newFeeAmount) : feeAmount),
      (data.newChuffedAmount ? Number(data.newChuffedAmount) : chuffedAmount),
      optionalPlatformDonations,
      feesByPaymentType,
    ),
    currency,
    perk_id: "",
    perksCart: formatPerks(data.perksCart),
    stripeToken: data.stripeToken ? data.stripeToken : null,
    paymentMethodTypes: data.paymentMethodTypes ? data.paymentMethodTypes : ["card"],
    card_brand: data.stripeCardBrand ? data.stripeCardBrand : null,
    card_country: data.stripeCardCountry ? data.stripeCardCountry : null,
    regular_giving: !(data.frequency === undefined || data.frequency.length === 0 || data.frequency === "one-off"),
    custom_questions: [],
    direct_debit_customer: directDebitCustomer || null,
    direct_debit_setup_intent: directDebitCheckoutSession || null,
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    ...retrieveUTM(),
  };

  formData.regular_giving_frequency = (formData.regular_giving === true) ? `${data.frequency}ly` : null;

  if (collectingTitle === true) {
    formData.title = data.title;
  }

  formData.regular_giving = !(data.frequency === undefined || data.frequency.length === 0 || data.frequency === "one-off");
  formData.regular_giving_frequency = (formData.regular_giving === true) ? `${data.frequency}ly` : null;

  // Add gift_aid to formData
  if (giftAidEnabled === true) {
    formData.giftAid = data.isGiftAidDeclared || false;
  }

  // Add phone info to formData
  if (collectingPhone === true) {
    formData.phone = data.phone;
  }

  // Add address info to formData
  // Fallback values set in case the fields are enabled, but not required (i.e., not filled in)
  if (collectingAddress === true) {
    formData.country = data.addressCountry || "";
    formData.address = data.addressLine1 || "";
    formData.locality = data.addressLocality || "";
    formData.region = data.addressRegion || "";
    formData.postcode = data.addressPostcode || "";
  }

  // For custom questions, include the question along with the answer
  if (donationFormCustomFields) {
    const enabledFields = enabledCustomFields(donationFormCustomFields);
    enabledFields.forEach((field, index) => {
      formData.custom_questions.push({
        question: field.label,
        answer: formatCustomQuestionFields(index, data),
      });
    });
  }

  return formData;
};

export const formatPerks = (perks) => {
  if (!perks) {
    return null;
  }

  return Array.isArray(perks) ? perks : [perks];
};

export const formatCustomQuestionFields = (index, data) => data[`customField${index}`];

export const formatDonationData = (donationData) => ({
  optionalPlatformDonations: donationData.feeStructure.optionalPlatformDonations,
  feesByPaymentType: donationData.feeStructure.feesByPaymentType,
  donationFormFields: donationData.donationFormFields,
  requiresOptIn: donationData.requiresOptIn,
  currency: donationData.feeStructure.currency,
  directDebitCustomer: donationData.directDebitCustomer,
  directDebitCheckoutSession: donationData.directDebitCheckoutSession,
  giftAidEnabled: donationData.giftAidEnabled,
  donationFormCustomFields: donationData.donationFormCustomFields,
});

const calculateDonationTotal = (donation, fee, chuffedAmount, optionalPlatformDonations, feesByPaymentType) => {
  let total = donation;

  if (optionalPlatformDonations === true) {
    total += chuffedAmount;

    if (feesByPaymentType === true) {
      total += fee;
    }
  }

  return total;
};
