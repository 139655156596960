// Given a donation amount, a payment processing fee amount, and a tier, return the value of the
// Chuffed optional donation amount
import { findKey } from "lodash";

const tests = [
  (amount) => amount > 0 && amount <= 5,
  (amount) => amount > 5 && amount < 25,
  (amount) => amount == 25,
  (amount) => amount > 25 && amount < 50,
  (amount) => amount == 50,
  (amount) => amount > 50 && amount < 100,
  (amount) => amount == 100,
  (amount) => amount > 100 && amount < 500,
  (amount) => amount == 500,
  (amount) => amount > 500 && amount < 1000,
  (amount) => amount == 1000,
  (amount) => amount > 1000,
];

const getMatrix = () => [
  [null, 1, 2, 5],
  [null, 3, 4, 5],
  [null, 4, 5, 6],
  [null, 5, 6, 8],
  [null, 9, 10, 15],
  [null, 9, 10, 15],
  [null, 17, 20, 35],
  [null, 20, 25, 35],
  [null, 35, 45, 65],
  [null, 55, 65, 75],
  [null, 85, 90, 105],
  [null, 115, 125, 135],
];

export function Calculator() {
  this.feeStructure = {};

  this.setFeeStructure = (feeStructure) => {
    this.feeStructure = feeStructure;
    return this;
  };

  this.calc = (amount, fee, tier, chuffedAmount) =>
    calculateOptionalAmount(amount, fee, tier, this.feeStructure, chuffedAmount);
}

export const calculateOptionalAmount = (amount, fee, tier, feeStructure, chuffedAmount) => {
  chuffedAmount = +chuffedAmount || 0;

  const feeAmount = Number.parseFloat(fee, 10);

  if (feeStructure.optionalPlatformDonations === false) {
    const platformPercent = Number.parseFloat(feeStructure.platformFeePercent, 10);
    return (amount * (platformPercent / 100)).toFixed(2);
  }

  if (tier === 4 && chuffedAmount) {
    return chuffedAmount;
  }

  // If the "other" field, return 0
  if (amount <= 0) {
    return 0;
  }

  const roundUp = feeStructure.feesByPaymentType === false ? 0 : Math.ceil(feeAmount) - feeAmount;

  const key = findKey(tests, (test) => test(amount));
  if (key) {
    const matrix = getMatrix();
    const val = matrix[key][tier];

    if (val === null || !val || val === undefined) {
      return 0;
    }

    return (val + roundUp).toFixed(2);
  }

  return (0 + roundUp).toFixed(2);
};
