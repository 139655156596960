import { fetch as router } from "helpers/router";
import fetch from "isomorphic-fetch";
import request from "utils/request";
import { captcha, csrf } from "utils/requestHeaders";
import { generateRequestData } from "widgets/Donation/store/paymentData";
import { donationFormHasAddress } from "widgets/Donation/utils/fieldHelpers";

// Initial State ----------------------

const initialState = {
  isLoading: true,
  paymentIntentsId: null,
  paymentIntentError: null,
  cardField: {
    error: "Please enter your card details",
    invalid: true,
    touched: false,
    value: undefined,
  },
  errorMessage: null,
  isError: false,
  isSubmitting: false,
  optionalTier: 2,
  directDebitSession: "",
  requiresManualCaptcha: null,
  recaptchaErrorType: null,
};

// Actions ----------------------

export const SUBMIT = "donation/SUBMIT";
const submit = () => ({
  type: SUBMIT,
});

export const SUBMIT_FAILURE = "donation/SUBMIT_FAILURE";
const submitFailure = (error) => ({
  type: SUBMIT_FAILURE,
  error,
});

export const OPTIONAL_TIER = "donation/OPTIONAL_TIER";
const optionalTier = (tier) => ({
  type: OPTIONAL_TIER,
  data: tier,
});

export const UPDATE_CARD = "donation/UPDATE_CARD";
const updateCard = (field) => ({
  type: UPDATE_CARD,
  data: field,
});

export const DIRECT_DEBIT_SESSION_FAILED = "donation/DIRECT_DEBIT_SESSION_FAILED";
const directDebitSessionFailed = (status) => ({
  type: DIRECT_DEBIT_SESSION_FAILED,
  data: status,
});

export const DIRECT_DEBIT_SESSION_COMPLETED = "donation/DIRECT_DEBIT_SESSION_COMPLETED";
const directDebitSessionCompleted = (status) => ({
  type: DIRECT_DEBIT_SESSION_COMPLETED,
  data: status,
});

export const CREATE_PAYMENT_INTENT_INITIATED = "donation/CREATE_PAYMENT_INTENT_INITIATED";
const createPaymentIntentInitiated = () => ({
  type: CREATE_PAYMENT_INTENT_INITIATED,
});

export const CREATE_PAYMENT_INTENT_FAILURE = "donation/CREATE_PAYMENT_INTENT_FAILURE";
const createPaymentIntentFailure = (error) => ({
  type: CREATE_PAYMENT_INTENT_FAILURE,
  error,
});

export const CREATE_PAYMENT_INTENT_SUCCESS = "donation/CREATE_PAYMENT_INTENT_SUCCESS";
const createPaymentIntentSuccess = (data) => ({
  type: CREATE_PAYMENT_INTENT_SUCCESS,
  data,
});

export const createPaymentIntent = (
  vendor,
  campaign,
  currency,
  captchaToken,
  paymentMethodTypes,
) => (dispatch) => {
  dispatch(createPaymentIntentInitiated());
  const url = router("api.v2.payments.payment-intent.store", { vendor, type: "connect" });

  const body = {
    campaign,
    currency,
    amount: 1000,
    paymentMethodTypes,
  };

  const req = request(
    url,
    "POST",
    {
      ...csrf(document),
      ...captcha(captchaToken, "v3"),
    },
    body,
  );

  return new Promise((resolve, reject) => {
    fetch(req)
      .then((response) => {
        if (response.status === 401) {
          throw Error();
        }
        return response.json();
      })
      .then((json) => {
        dispatch(createPaymentIntentSuccess(json.data?.code));
      })
      .catch((error) => {
        dispatch(createPaymentIntentFailure(error));
        dispatch(createPaymentIntentFailure("critical"));
        reject(error);
      });
  });
};

export const submitPaymentElement = (data) => (dispatch) => {
  dispatch(submit());
  const formBody = generateRequestData(data, "update_payment_intent");
  const url = router("web.pay.payment.stripe.intent.update", { campaign: data.recipientId });
  const req = request(url, "PATCH", csrf(document), formBody);

  return new Promise(() => {
    fetch(req)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setTimeout(() => {
            data
              .submitPaymentElementPaymentIntent({
                cardErrorHandler: data.cardErrorHandler,
                dispatch,
                elements: data.elements,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                paymentIntentsId: data.paymentIntentsId,
                selectedPaymentMethodType: data.selectedPaymentMethodType,
                returnUrl: json.data.redirectUrl,
                stripe: data.stripe,
                address: {
                  line1: data.addressLine1 ?? "",
                  // line2 must be provided in the data, but we never collect it via Chuffed, so this is always blank
                  line2: "",
                  city: data.addressLocality ?? "",
                  state: data.addressRegion ?? "",
                  postal_code: data.addressPostcode ?? "",
                  country: getPaymentIntentCountry(json),
                },
                donationFormHasAddress: donationFormHasAddress(data.donationFormFields),
              });
          }, 1000);
        } else {
          dispatch(submitFailure(json.message));
        }
      });
  });
};

const submitStripePayment = (data, type, dispatch) => {
  let url = router("web.pay.payment.stripe.direct-debit.store", { campaign: data.recipientId });
  // for generic stripe payment
  if (type === "update_payment_intent") {
    dispatch(submit());

    url = router("web.pay.payment.stripe.intent.update", { campaign: data.recipientId });
  }

  const formBody = generateRequestData(data, type);

  const method = type === "update_payment_intent" ? "PATCH" : "POST";
  const req = request(url, method, csrf(document), formBody);

  if (type === "direct_debit") {
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response.status !== 200) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then((response) => response.json())
        .then((json) => {
          if (json.success === "success") {
            dispatch(directDebitSessionCompleted());
            resolve(json);
          } else {
            dispatch(directDebitSessionFailed(json.message));
          }
        })
        .catch((error) => {
          dispatch(directDebitSessionFailed(error.message));
          reject(error);
        });
    });
  }

  return new Promise((resolve, reject) => {
    fetch(req)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          const stripeMethods = data?.stripe;
          const cardElement = data?.cardElem;
          data
            .submitPaymentIntent(
              data,
              json.data.redirectUrl,
              data.closeFeeConfirmation,
              data.paymentMethod,
              data.paymentIntentComplete,
              stripeMethods,
              cardElement,
              data.cardErrorHandler,
            );
        } else {
          dispatch(submitFailure(json.message));
        }
      })
      .catch((error) => {
        dispatch(submitFailure(error.message));
        reject(error.message);
      });
  });
};

export const submitDonation = (data) => (dispatch) => {
  dispatch(submit());
  // if no payment method, means shared/flat fee which requires no pre-selection of payment method
  const paymentProvider = data.paymentMethod || data.submitButton;

  if (paymentProvider !== "paypal") {
    // Unsupported payment method, do nothing
    return;
  }

  const url = router("web.pay.payment.paypal-marketplace", { campaign: data.recipientId });

  const formData = generateRequestData(data, "paypal");

  const req = request(url, "POST", csrf(document), formData);

  return new Promise((resolve, reject) => {
    fetch(req)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          window.location = json.redirectUrl;
          resolve(json);
        } else {
          dispatch(submitFailure(json.message));
        }
      })
      .catch((error) => {
        dispatch(submitFailure(error));
        reject(error);
      });
  });
};

export const updatePaymentIntent = (data) => (dispatch) => {
  submitStripePayment(data, "update_payment_intent", dispatch);
};

export const submitDirectDebit = (data) => (dispatch) => {
  submitStripePayment(data, "direct_debit", dispatch);
};

export const setOptionalTier = (tier) => (dispatch) => {
  dispatch(optionalTier(tier));
};

export const setCardField = (field) => (dispatch) => {
  dispatch(updateCard(field));
};

export const INITIALIZE_DONATION_FORM = "donation/INITIALIZE_DONATION_FORM";
export const initializeDonationForm = () => ({
  type: INITIALIZE_DONATION_FORM,
});

const getPaymentIntentCountry = (updateData) =>
  updateData.data.donationCountry?.country_iso_code?.toUpperCase() ?? null;

// Reducers ----------------------
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INITIALIZE_DONATION_FORM:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_PAYMENT_INTENT_INITIATED:
      return {
        ...state,
        paymentIntentsId: null,
      };
    case CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentIntentsId: action.data,
        requiresManualCaptcha: false,
      };
    case CREATE_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        paymentIntentError: action.error,
        requiresManualCaptcha: true,
        recaptchaErrorType: action.error,
      };
    case SUBMIT:
      return {
        ...state,
        errorMessage: "",
        isError: false,
        isSubmitting: true,
      };
    case SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error || "Unable to make payment. Please try again.",
        isError: true,
        isSubmitting: false,
      };
    case OPTIONAL_TIER:
      return {
        ...state,
        isLoading: false,
        optionalTier: action.data,
      };
    case UPDATE_CARD:
      return {
        ...state,
        cardField: {
          ...state.cardField,
          ...action.data,
        },
      };
    case DIRECT_DEBIT_SESSION_FAILED:
      return {
        ...state,
        directDebitSession: "failed",
        errorMessage: "",
        isLoading: false,
        isError: false,
        isSubmitting: true,
      };
    case DIRECT_DEBIT_SESSION_COMPLETED:
      return {
        ...state,
        directDebitSession: "completed",
        isLoading: false,
        errorMessage: "",
        isError: false,
        isSubmitting: true,
      };
    default:
      return state;
  }
}
