export const calculateDonationTotal = (feeStructure, formProps, values) => {
  if (!feeStructure) {
    throw Error("No fee structure provided");
  }

  const donationValue = Number.parseFloat((formProps?.values?.donationAmount ?? values?.donationAmount), 10);
  const processingFee = Number.parseFloat((formProps?.values?.feeAmount ?? values?.feeAmount), 10);
  const chuffedValue = Number.parseFloat((formProps?.values?.chuffedAmount ?? values?.chuffedAmount), 10);

  if (feeStructure.optionalPlatformDonations === false) {
    return donationValue;
  }

  if (feeStructure.feesByPaymentType === false) {
    return donationValue + chuffedValue;
  }

  return (donationValue + processingFee + chuffedValue);
};

export const calculatePlatformFeeTotal = (formValues) => {
  const {
    chuffedAmount,
    feeAmount,
  } = formValues;

  const processingFee = Number.parseFloat(feeAmount, 10);
  const chuffedValue = Number.parseFloat(chuffedAmount, 10);

  return (processingFee + chuffedValue);
};
