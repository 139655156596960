import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InfoHelper from "../InfoHelper";

import "./_style.scss";

const InputGroup = ({
  children,
  className,
  description,
  inline,
  inlineAlign,
  inlineWidth,
  isFlashHighlight,
  label,
  look,
  size,
  spacing,
  v2,
  marginBottom,
  ...other
}) => (
  <div
    {...other}
    className={
      classNames(
        "input-group",
        className,
        {
          "--inline": inline,
          [`--inline--align-${inlineAlign}`]: inlineAlign,
          [`--inline--${inlineWidth}`]: inlineWidth,
          "--is-flash-highlight": isFlashHighlight,
          [`--${size}`]: size,
          [`--${look}`]: look,
          [`--spacing-${spacing}`]: spacing,
          "--v2": v2,
          [`--margin-bottom-${marginBottom}`]: marginBottom,
        },
      )
    }
  >
    <div className="input-group__label">
      <div className="input-group__label-content">
        <span className="input-group__label-text">
          {label}
        </span>
        {/* if inline && description this will be hidden via css */}
        {
          description
            ? (
              <span className="input-group__description">
                {description}
              </span>
            )
            : null
        }
        {
          inline && description
            ? (
              <InfoHelper
                className="input-group__info-helper"
                name={label.replace(" ", "")}
              >
                {description}
              </InfoHelper>
            )
            : null
        }
      </div>
    </div>
    <div className="input-group__inputs">
      {children}
    </div>
  </div>
);

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inline: PropTypes.bool,
  inlineAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
  inlineWidth: PropTypes.oneOf(["sixth", "quarter", "third"]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  look: PropTypes.oneOf(["sentence-case"]),
  size: PropTypes.oneOf(["small", "regular", ""]),
  spacing: PropTypes.oneOf(["none", "xsmall", "small"]),
  v2: PropTypes.bool,
};

InputGroup.defaultProps = {
  description: null,
  size: "regular",
};

export default InputGroup;
