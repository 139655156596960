export const fundraiserChange = (e, donationFormData, handlers, formProps) => {
  const {
    recipient,
    fundraisers,
  } = donationFormData;
  const { setTarget } = handlers;
  const { setFieldValue } = formProps;
  const selectedValue = Number(e.value);
  const recipientId = Number(donationFormData.recipientId);
  let target;

  if (parseInt(selectedValue, 10) === recipientId) {
    target = recipient;
  } else {
    fundraisers.forEach((fundraiser) => {
      if (fundraiser.id === parseInt(selectedValue, 10)) {
        target = fundraiser;
      }
    }, selectedValue);
  }

  setTarget(target);
  setFieldValue("targetId", target.id);

  return target;
};
