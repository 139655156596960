import MarkdownIt from "markdown-it";

const videoProviderRegex = () => [
  /https:\/\/www.youtube.com/,
];

const md = new MarkdownIt({ html: true });
// video embed
md.use((md) => {
  const defaultRender = md.renderer.rules.image;
  md.renderer.rules.image = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    const assetSources = token.attrIndex("src");
    const assetUrl = token.attrs[assetSources][1];
    for (const reg of videoProviderRegex()) {
      if (reg.test(assetUrl)) {
        const parts = assetUrl.split("?v=");
        return `<iframe width="100%" height="300" src="https://www.youtube.com/embed/${parts[1]
        }" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; `
          + "picture-in-picture\"></iframe>";
      }
    }
    return defaultRender(tokens, idx, options, env, self);
  };
});

export const render = (src) => md.render(src).trim();
