import {
  paymentVendorsToDisplay,
  shouldDisplayCampaignCard,
  shouldDisplayCampaignRecipientLabel,
  shouldDisplayCustomQuestions,
  shouldDisplayDetailsSection,
  shouldDisplayDirectDebit,
  shouldDisplayDisclaimer,
  shouldDisplayDonationSelectorHeading,
  shouldDisplayFundraisersDropdown,
  shouldDisplayMatchedGiving,
  shouldDisplayOptionalDonationSelector,
  shouldDisplayPaymentMethodSelection,
  shouldDisplayPaymentProcessingFees,
  shouldDisplayRegularGiving,
  typeOfdonationSelector,
} from "widgets/Donation/utils/helpers";

import React from "react";
import { formComponents } from "./formComponents";

export const buildArrayOfDonationComponents = (donationFormData, handlers, formProps, donationFormContext) => {
  const elements = elementBuilder(donationFormData, donationFormContext);

  const componentNames = Object.keys(formComponents)
    .filter((key) => elements.includes(key))
    .reduce((obj, key) => {
      obj[key] = formComponents[key];
      return obj;
    }, {});

  return Object.entries(componentNames).map((component, i) => (
    <React.Fragment key={i}>
      {component[1](donationFormData, i, formProps, handlers, donationFormContext)}
    </React.Fragment>
  ));
};

export const elementBuilder = (donationFormData, donationFormContext) => {
  const elements = [
    "heading",
    "paymentSectionSubheading",
  ];

  shouldDisplayDonationSelectorHeading(donationFormData, elements);
  shouldDisplayCampaignCard(donationFormData, elements);
  shouldDisplayDisclaimer(donationFormData, elements);
  shouldDisplayMatchedGiving(donationFormData, elements);
  shouldDisplayDirectDebit(donationFormData, donationFormContext, elements);
  shouldDisplayPaymentMethodSelection(donationFormData, elements);
  shouldDisplayFundraisersDropdown(donationFormData, elements);
  typeOfdonationSelector(donationFormData, donationFormContext, elements);
  shouldDisplayRegularGiving(donationFormData, elements);
  shouldDisplayCampaignRecipientLabel(donationFormData, elements);
  shouldDisplayPaymentProcessingFees(donationFormData, elements);
  shouldDisplayOptionalDonationSelector(donationFormData, elements);
  shouldDisplayDetailsSection(donationFormData, elements);
  shouldDisplayCustomQuestions(donationFormData, elements);
  paymentVendorsToDisplay(donationFormData, donationFormContext, elements);

  return elements;
};
