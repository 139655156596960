import React, { useEffect, useState } from "react";

import ErrorDisplay from "components/ErrorDisplay";
import LinkButton from "components/LinkButton";

import TextTranslator from "components/TranslateText/TextTranslator";
import { formatCurrency } from "helpers/formatCurrency";
import DonationCardElement from "widgets/Donation/components/SplitCardElements";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { isCardPayment, shouldDisplayPaymentMethod } from "widgets/Donation/utils/helpers";
import { frequencyLabel } from "widgets/Donation/utils/regularGivingHelpers";
import {
  elements,
  handleCardChange, handleStripeSubmit, invalidCardClass, isSubmittingDonation,
} from "../utils";

export default function StripeCardElements({
  data, formProps, handlers, donationFormContext,
}) {
  const {
    recipient: {
      camp_status,
    },
    feeStructure,
  } = data;

  const [loading, setLoading] = useState(false);
  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  const regularGivingContext = { ...data, ...formProps };
  const {
    values: {
      paymentMethod,
    },
    setFieldValue,
  } = formProps;

  const {
    donation: {
      cardField,
      isSubmitting,
      isError,
      errorMessage,
    },
  } = donationFormContext;

  useEffect(() => {
    if (isSubmittingDonation(isSubmitting, paymentMethod, feeStructure, donationFormContext)) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }

    return (() => {
      setLoading(false);
    });
  }, [isSubmitting, paymentMethod, feeStructure, donationFormContext]);

  if (!shouldDisplayPaymentMethod("stripe", { paymentMethod, data })) {
    return null;
  }

  return (
    <div className="donation-form__payment-method" data-test="donation-form__stripe">
      <ErrorDisplay
        errorKey="donation-form-stripe-error-display"
        alwaysShowErrors={cardField.touched && cardField.invalid}
        field={cardField}
      >
        <DonationCardElement
          invalidCardClass={invalidCardClass(cardField)}
          onChange={(event) => handleCardChange("change", event, handlers.setCardField, elements)}
          onBlur={(event) => handleCardChange("blur", event, handlers.setCardField, elements)}
        />
      </ErrorDisplay>
      <LinkButton
        id="js-donate-action-stripe"
        isBold
        isDisabled={camp_status === "draft" || loading || donationFormContext.isPaymentRequestMethod}
        isFullWidth
        isLoading={loading}
        loadingMessage={<TextTranslator>Submitting payment...</TextTranslator>}
        look="primary"
        type="submit"
        onClick={() => {
          setFieldValue("submitButton", "card");
          handleStripeSubmit(handlers);
        }}
        size="large"
        className="mt-1"
      >
        <TextTranslator replacements={
          {
            currency: formatCurrency(donationTotal, feeStructure),
            frequency: frequencyLabel(true, false, regularGivingContext),
          }
        }
        >
          Pay :currency:frequency via card
        </TextTranslator>
      </LinkButton>
      {
        (
          (
            isCardPayment(paymentMethod) || feeStructure.feesByPaymentType === false
          ) && isError
        )
        && (
        <div className="donation-form__error">
          { errorMessage }
        </div>
        )
      }
    </div>
  );
}
