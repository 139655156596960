import { useField } from "formik";
import React from "react";

import ErrorDisplay from "components/ErrorDisplay";
import PaymentToggle from "components/PaymentToggle";

import { getPaymentMethodOptions } from "widgets/Donation/utils/getPaymentMethodOptions";

const PaymentToggleWrapper = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const { handlePaymentMethodChanges } = props.handlers;

  const changePayment = (
    value,
  ) => handlePaymentMethodChanges(value, props.data, props, props.donationFormContext.optionalTier);

  return (
    <div className="donation-form__fieldset" data-test="donation-form__payment-methods">
      <ErrorDisplay
        alwaysShowErrors={meta.touched && meta.error?.length > 0}
        customMsg={meta.error}
        field={field}
      >
        <PaymentToggle
          defaultValue={field.value}
          options={getPaymentMethodOptions(props, props.values.frequency)}
          shouldShowFees={false}
          field={field}
          onChange={changePayment}
        />
      </ErrorDisplay>
    </div>
  );
};

export const createPaymentSelector = (data, i, formProps, handlers, donationFormContext) => (
  <PaymentToggleWrapper
    key={i}
    label="Payment Method"
    name="paymentMethod"
    type="select"
    data={data}
    handlers={handlers}
    donationFormContext={donationFormContext}
    {...formProps}
  />
);
