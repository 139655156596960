import {
  createStore, applyMiddleware, compose, combineReducers,
} from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";

import donationReducer from "./state";

const rootReducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
  donation: donationReducer,
});

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunkMiddleware),
    // redux chrome devtools:
    // https://github.com/zalmoxisus/redux-devtools-extension
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

export default store;
