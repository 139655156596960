const validateBoolean = (value) => {
  if (!value) {
    if (value !== false) {
      return;
    }
  }

  if (value === "true" || value === true) {
    return true;
  }

  if (value === "false" || value === false) {
    return false;
  }
};

export default validateBoolean;
