import React from "react";
import TextHeading from "components/TextHeading";

import { getStepHeading } from "widgets/Donation/utils/helpers";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createSectionSubheading = (donationFormData, i, formProps, handlers, donationFormContext, heading) => {
  const headingText = heading.toLowerCase();

  return (
    <div className={`donation-form__heading --${headingText}`}>
      <TextHeading size={6} uppercase data-testid={`donation-form-heading-${headingText}`}>
        <TextTranslator>
          {
          getStepHeading(
            heading,
            { ...donationFormData, ...donationFormContext.donation },
            donationFormContext.showDonation,
          )
          }
        </TextTranslator>
      </TextHeading>
    </div>
  );
};
