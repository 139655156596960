import React from "react";
import classnames from "classnames";

import { handleDonationAmountChanges } from "widgets/Donation/utils/helpers";

import CustomSelect from "components/CustomSelect";

export const isAmountOfPerksUnAvailable = (
  perksCart,
  value,
) => perksCart.quantity !== 0 && perksCart.available < value || perksCart.multiple == false && value > 1;

export const perkHasLimitedRemaining = (
  perksCart,
) => perksCart.multiple !== 0 && perksCart.quantity !== 0 && perksCart.available <= 10;

export const perkQuantityLimited = (perksCart) => !perksCart.multiple;

export const perkHasQuestions = (
  perksCart,
) => perksCart.enable_question && (perksCart.question !== null && perksCart.question !== undefined);

export const updatePerkAnswer = (answer, perksCart, formProps) => {
  perksCart.answer = answer;
  formProps.setFieldValue("perksCart", perksCart);
};

export const generatePerkQuestionElement = (perksCart, formProps, donationFormData) => (
  <div id="donation-form__perk-question">
    <div className="donation-form__line-item">
      <div className="input-row --select">
        <label className="donation-form__perk-question">
          { perksCart.question?.question }
        </label>
        <CustomSelect
          name="perkSelector"
          onChange={(e) => updatePerkAnswer(e.target.value, perksCart, formProps)}
          options={
            perksCart.question?.options.map((option) => ({
              name: option.toString(),
              text: option.toString(),
              value: option.toString(),
            }))
          }
          value={
            (perksCart?.answer !== undefined || donationFormData?.prefill?.perk_answer)
              ? (perksCart?.answer?.toString() || donationFormData?.prefill?.perk_answer)
              : updatePerkAnswer(perksCart?.question?.options[0], perksCart, formProps)
          }
        />
      </div>
    </div>
  </div>
);

export const changePerkQuantity = async (value, donationFormData, formProps, handlers, donationFormContext) => {
  const {
    values: {
      perksCart,
    },
  } = formProps;

  if (value === "donation") {
    handlers.setShowDonation(true);
  }

  const normalizeValue = Number(value);

  if (isAmountOfPerksUnAvailable(perksCart, normalizeValue)) {
    return false;
  }

  perksCart.count = normalizeValue;
  const donationTotal = perksCart.price * normalizeValue;

  formProps.setFieldValue("donationAmount", donationTotal);

  await handleDonationAmountChanges(donationTotal, donationFormData, formProps, donationFormContext.optionalTier);

  handlers.setShowDonation(false);

  formProps.setFieldValue("perksCart", perksCart);
};

const CreatePerkSelector = (donationFormData, i, formProps, handlers, donationFormContext) => {
  const {
    values: {
      perksCart,
    },
  } = formProps;

  /* eslint react-hooks/exhaustive-deps: "off" */
  React.useEffect(() => {
    let prefillPerksQuantity = 1;

    // If the number of perks has been set in the form prefill data, then use that
    // otherwise, use the default of 1
    if (donationFormData?.prefill) {
      prefillPerksQuantity = donationFormData.prefill.perk_multiplier;
    }

    changePerkQuantity(
      prefillPerksQuantity,
      donationFormData,
      formProps,
      handlers,
      donationFormContext,
    );
  }, [donationFormData]);

  const perkValue = (perksCart.count >= 5 && perksCart.count <= 10 ? perksCart.count : 5).toString();

  const changePerkQuantityHandler = (
    e,
  ) => changePerkQuantity(e.target.value, donationFormData, formProps, handlers, donationFormContext);

  const perkOptions = [5, 6, 7, 8, 9, 10]
    .map((option) => ({
      name: option.toString(),
      text: option.toString(),
      value: option.toString(),
      disabled: (perksCart.multiple === false || (perksCart.quantity !== 0 && perksCart.available < option)),
    }));

  return (
    <div key={i} className="donation-form__perk-container" data-test="donation-form-perk-container">
      <div className="donation-form__line-item">
        <div id="donation-form__perk-quantity" style={{ width: "100%" }}>
          <label className="donation-form__perk-question" data-test="select-perk-title">
            Select quantity of
            {" "}
            { perksCart.title }
            :
          </label>
          <div className="donation-form__options">
            {[1, 2, 3, 4].map((value, index) => (
              <label
                className={
                    classnames(
                      "donation-form__option",
                      {
                        "--active": Number(perksCart.count) === Number(value),
                        "--disabled": ((perksCart.multiple == false && value > 1) || (perksCart.quantity !== 0 && perksCart.available < value)),
                      },
                    )
                  }
                htmlFor={`perksCart-option${index}`}
                key={index}
                title={(perksCart.quantity !== 0 && perksCart.available < value) ? "Not enough" : null}
              >
                { value }
                <input
                  className="donation-form__option-input"
                  data-index={index}
                  data-test="donation-form-perk"
                  disabled={(
                      (perksCart.multiple == false && value > 1)
                      || (perksCart.quantity !== 0 && perksCart.available < value)
                    )}
                  id={`perksCart-option${index}`}
                  name="perksCart.count"
                  onChange={changePerkQuantityHandler}
                  type="radio"
                  value={value}
                />
              </label>
            ))}

            <CustomSelect
              className={
                classnames(
                  {
                    "--active": perksCart.count >= 5 && perksCart.count <= 10,
                    "--disabled": (perksCart.multiple == false || (perksCart.quantity !== 0 && perksCart.available < 5)),
                  },
                )
              }
              data-test="multiple-perk-selector"
              name="perkCountSelector"
              onBlur={changePerkQuantityHandler}
              onChange={changePerkQuantityHandler}
              options={perkOptions}
              value={perkValue}
            />
          </div>

          {
            perkQuantityLimited(perksCart) && <span className="donation-form__only-available">Maximum 1 per donation.</span>
          }

          {
            perkHasLimitedRemaining(perksCart) && (
            <span className="donation-form__only-available">
              Only
              {" "}
              { perksCart.available }
              {" "}
              remaining.
            </span>
            )
          }
        </div>
      </div>

      {
        perkHasQuestions(perksCart) ? generatePerkQuestionElement(perksCart, formProps, donationFormData) : null
      }
    </div>
  );
};

export default CreatePerkSelector;
