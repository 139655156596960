import React from "react";
import { useField } from "formik";

import OptionalSelector from "components/OptionalSelector";
import { createDonationTotal } from "widgets/Donation/components/DonationTotal/createDonationTotal";

export const OptionalSelectorWrapper = ({ ...props }) => {
  const [field, setFieldValue] = useField(props);
  const regularGivingContext = { ...props.data, ...props };
  const { feeStructure } = props.data;
  const { donationAmount, feeAmount } = props.values;
  const openCurrencyModal = props.handlers.openCurrencyModal();

  return (
    <>
      <OptionalSelector
        donationValue={Number.parseFloat(donationAmount)}
        feeStructure={feeStructure}
        feeValue={Number.parseFloat(feeAmount)}
        field={field}
        onChange={(e) => setFieldValue("chuffedAmount", (e.target.value || 0))}
        onBlur={(e) => setFieldValue("chuffedAmount", (e.target.value || 0))}
        onOptionalSelectChange={(e) => props.handlers.setOptionalTier(e)}
        optionalTier={props.donationFormContext.optionalTier}
        setFieldValue={(value) => props.setFieldValue("chuffedAmount", (value || 0))}
      />
      {
        createDonationTotal(feeStructure, openCurrencyModal, props, regularGivingContext)
      }
    </>
  );
};

export const createOptionalSelector = (data, i, formProps, handlers, donationFormContext) => (
  <OptionalSelectorWrapper
    key={i}
    label="Optional Selector"
    name="chuffedAmount"
    type="number"
    data={data}
    handlers={handlers}
    donationFormContext={donationFormContext}
    {...formProps}
  />
);
