import ErrorDisplay from "components/ErrorDisplay";
import ImpactSelector from "components/ImpactSelector";
import { useField } from "formik";
import React from "react";

import { handleDonationAmountChanges } from "widgets/Donation/utils/helpers";

const ImpactLevelWrapper = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const {
    levels,
    feeStructure,
  } = props.data;

  const {
    optionalTier,
  } = props.donationFormContext;

  return (
    <ErrorDisplay
      alwaysShowErrors={meta.touched && meta.error?.length > 0}
      customMsg={meta.error}
      field={field}
    >
      <ImpactSelector
        data-test="impact-selector"
        currencySymbol={feeStructure.currencySymbol}
        field={field}
        impactLevels={levels}
        handleImpactChange={(value) => handleDonationAmountChanges(value, props.data, props, optionalTier)}
        setFieldValue={props.setFieldValue}
      />
    </ErrorDisplay>
  );
};

export const createImpactLevels = (data, i, formProps, handlers, donationFormContext) => (
  <ImpactLevelWrapper
    key={i}
    label="Impact Selector"
    name="donationAmount"
    type="text"
    data={data}
    handlers={handlers}
    donationFormContext={donationFormContext}
    {...formProps}
  />
);
