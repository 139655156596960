import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./_style.scss";

const InputRow = ({
  children,
  spacing,
  type,
  ...other
}) => (
  <div
    data-test="input-row"
    {...other}
    className={
      classNames(
        "input-row",
        {
          [`--${type}`]: !!type,
          [`--spacing-${spacing}`]: !!spacing,
        },
      )
    }
  >
    {React.Children.map(children, (child) => {
      if (child !== null) {
        return (
          <div className="input-row__item" data-test="input-row-item">
            {child}
          </div>
        );
      }
      return null;
    })}
  </div>
);

InputRow.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOf(["none", "small"]),
  type: PropTypes.oneOf(["address", "checkbox", "twocol", "fullname-title"]),
};

export default InputRow;
