import React from "react";

import { renderDonorDetails } from "widgets/Donation/components/Details/renderDonorDetails";

export const createDetails = (donationFormData, i, formProps) => (
  <>
    {
      renderDonorDetails(donationFormData, formProps)
    }
  </>
);
