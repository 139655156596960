import React from "react";

import { formatCurrency } from "helpers/formatCurrency";

import "./_style.scss";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createDonationToCampaignLabel = (data, i, formProps, handlers, donationFormContext) => {
  const {
    values: {
      donationAmount,
      perksCart,
    },
  } = formProps;

  const { feeStructure } = data;
  const title = donationFormContext?.target?.title;

  return (
    perksCart
      ? (
        <div className="donation-form__line-item --donation donation-campaign-label__container">
          <span className="donation-form__line-item-label">
            <span dangerouslySetInnerHTML={{ __html: (`${perksCart && (perksCart?.count || 0)} &times; ${perksCart && perksCart?.title}`) }} />
            :
          </span>
          <span className="donation-form__line-item-value" data-test="donation-form-perk-total">
            {formatCurrency(donationAmount, feeStructure, false)}
          </span>
        </div>
      )
      : (
        <div className="donation-form__line-item --donation donation-campaign-label__container" key={i}>
          <span className="donation-form__line-item-label">
            <TextTranslator>To</TextTranslator>
            {" "}
            <span>{title}</span>
            :
          </span>
          <span className="donation-form__line-item-value">
            { formatCurrency(donationAmount, feeStructure, false) }
          </span>
        </div>
      )
  );
};
