export const remapIndividualFundraiser = (fundraiser) => ({
  advocate: fundraiser.advocate || null,
  camp_amount: fundraiser.target && Number(fundraiser.target.amount) / 100,
  camp_amount_collected: fundraiser.collected && Number(fundraiser.collected?.amount) / 100,
  camp_image: fundraiser.assets?.length >= 1 && fundraiser.assets[0].url,
  camp_status: fundraiser.status,
  id: Number(fundraiser.id),
  photo: fundraiser.fundraiserProfile,
  slug: fundraiser.slug,
  team_id: Number(fundraiser.team?.id),
  team_logo: fundraiser.team?.logo,
  team_name: fundraiser.team?.title,
  title: fundraiser.title,
  user_name: fundraiser.fundraiserName,
});

export const remapFundraisers = (fundraisers) => {
  if (!fundraisers) {
    return null;
  }

  const filterDraftFundraisers = fundraisers.filter((fundraiser) => fundraiser.status !== "Draft");

  return filterDraftFundraisers.map((fundraiser) => remapIndividualFundraiser(fundraiser.node));
};
