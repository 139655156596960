import { isCardPayment } from "widgets/Donation/utils/helpers";
import { PaymentMethodTypes } from "widgets/Donation/types";

export const invalidCardClass = (cardField) => (cardField.touched && cardField.invalid ? " --invalid" : "");

export const elements = () => {
  /**
    * Currently to validate all fields - Stripe has no public api method, so this is suggested
    * https://github.com/stripe/react-stripe-elements/issues/283
  * */
  const cardNumberValid = document.getElementsByClassName("StripeElement")[0].classList.contains("StripeElement--complete");
  const cardDetailsValid = document.getElementsByClassName("StripeElement")[1].classList.contains("StripeElement--complete");
  const cvcValid = document.getElementsByClassName("StripeElement")[2].classList.contains("StripeElement--complete");

  return {
    cardNumberValid,
    cardDetailsValid,
    cvcValid,
  };
};

// Listen to the Stripe `card` element so the donation form can keep track of if it's valid state
export const handleCardChange = (eventType, changeEventData, setCardField, elements) => {
  const validChangeEvent = eventType === "change" ? (changeEventData.complete === true || changeEventData.empty === false) : changeEventData.complete === true;

  if ((changeEventData.complete && (elements().cardNumberValid && elements().cardDetailsValid))
    || (changeEventData.complete && (elements().cardNumberValid && elements().cvcValid))
    || (changeEventData.complete && (elements().cardDetailsValid && elements().cvcValid))
    || validChangeEvent
  ) {
    // If complete, then the card field is valid
    setCardField({
      error: undefined,
      invalid: false,
    });
  } else if (!elements().cardNumberValid || !elements().cardDetailsValid || !elements().cvcValid) {
    // If there's a specific error message, display that
    setCardField({
      error: changeEventData?.error?.message || "Please enter your card details",
      invalid: true,
      touched: true,
    });
  }
};

export const handleStripeSubmit = (handlers) => {
  handlers.setIsPaymentRequestMethod(false);
  // Ensure that we display an error message by setting `touched` to true
  handlers.setCardField({
    touched: true,
  });
};

export const isSubmittingDonation = (isSubmitting, paymentMethod, feeStructure, donationFormContext) => isSubmitting
  && (isCardPayment(paymentMethod) || !feeStructure.feesByPaymentType)
  && !donationFormContext.isPaymentRequestMethod;

export const getPayButtonLabel = (paymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethodTypes.Bacs:
      return "via Direct Debit";
    case PaymentMethodTypes.Card:
      return "with Card";
    default:
      return "";
  }
};
