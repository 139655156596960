import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBoundary } from 'react-error-boundary';
import { hasBacsPaymentMethod } from 'widgets/Donation/utils/getPaymentMethodOptions';
import ErrorFallback from '../../ErrorFallback/ErrorFallback';
import StripeCardElements from './StripeCardElements/StripeCardElements';
import StripePaymentElementWrapper from './StripePaymentElement/StripePaymentElementWrapper';
export default function StripeVendorInterface(data, index, formProps, handlers, donationFormContext) {
    const shouldShowPaymentElement = hasBacsPaymentMethod(data);
    return (_jsx(ErrorBoundary, { fallback: _jsx(ErrorFallback, {}), children: shouldShowPaymentElement ? (_jsx(StripePaymentElementWrapper, { data: data, formProps: formProps, handlers: handlers, donationFormContext: donationFormContext })) : (_jsx(StripeCardElements, { data: data, formProps: formProps, handlers: handlers, donationFormContext: donationFormContext })) }));
}
