import React, { useEffect } from "react";

const TurnstileWrapper = ({ onChange, captchaKey, setTurnstileInteractive }) => {
  window.onVerifyTurnstile = (token) => onChange(token);
  window.setTurnstileIsInteractive = () => setTurnstileInteractive(true);
  window.setTurnstileNotInteractive = () => setTurnstileInteractive(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.async = true;
    document.head.appendChild(script);
  }, [captchaKey]);

  return (
    <div
      className="cf-turnstile"
      data-sitekey={captchaKey}
      data-callback="onVerifyTurnstile"
      data-appearance="interaction-only"
      data-before-interactive-callback="setTurnstileIsInteractive"
      data-after-interactive-callback="setTurnstileNotInteractive"
    />
  );
};

export default TurnstileWrapper;
