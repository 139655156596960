import React from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";

import InputGroup from "components/InputGroup";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const createOptions = {
  style: {
    base: {
      color: "#000",
      fontFamily: "\"Source Sans Pro\", \"Helvetica Neue\", Helvetica, Arial, sans-serif",
      fontSize: "16px",
      fontWeight: "400",
      "::placeholder": {
        color: "#c3c3c3",
        fontFamily: "\"Source Sans Pro\", \"Helvetica Neue\", Helvetica, Arial, sans-serif",
        fontSize: "16px",
        fontWeight: "300",
      },
    },
    invalid: {
      color: "red",
    },
  },
};

const DonationCardElement = ({
  invalidCardClass,
  onChange,
  onBlur,
}) => {
  const { translate: t } = useLanguageProvider();
  return (
    <>
      <label>
        <InputGroup label={t("Credit or debit card number")} look="sentence-case" spacing="xsmall">
          <div className={`donation-form__input-card${invalidCardClass}`}>
            <CardNumberElement
              onChange={onChange}
              onBlur={onBlur}
              options={{ ...createOptions, ...{ showIcon: true } }}
            />
          </div>
        </InputGroup>
      </label>
      <section className="donation-form__input-card-expiry-cvc">
        <label>
          <InputGroup label={t("Expiration date")} look="sentence-case" spacing="xsmall">
            <div className={`donation-form__input-card${invalidCardClass}`}>
              <CardExpiryElement
                onChange={onChange}
                onBlur={onBlur}
                options={{ ...createOptions }}
              />
            </div>
          </InputGroup>
        </label>
        <label>
          <InputGroup label={t("CVC")} look="sentence-case" spacing="xsmall">
            <div className={`donation-form__input-card${invalidCardClass}`}>
              <CardCvcElement
                onChange={onChange}
                onBlur={onBlur}
                options={{ ...createOptions }}
              />
            </div>
          </InputGroup>
        </label>
      </section>
    </>
  );
};

export default DonationCardElement;
