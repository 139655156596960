import TextTranslator from "components/TranslateText/TextTranslator";
import React from "react";
import { isCustomTemplate } from "widgets/Donation/utils/helpers";

export const createDonationFormHeading = (donationFormData) => {
  if (isCustomTemplate(donationFormData)) {
    return (
      <div
        className="payment__header --v2 donation-form__fundraiser-dropdown"
        data-test="fundraiser-dropdown"
      />
    );
  }
  return (
    <div
      className="payment__header --v2 donation-form__fundraiser-dropdown"
      data-test="fundraiser-dropdown"
    >
      <div className="text-heading --size-6"><TextTranslator>You're donating to</TextTranslator></div>
      <div className="payment__donate-heading">
        <h1 className="text-heading --size-3">{donationFormData.recipient.title}</h1>
        <div className="text-heading --size-7 --light">
          <TextTranslator replacements={{ name: donationFormData.ownerName }}>By :name</TextTranslator>
        </div>
      </div>
    </div>
  );
};
