import ShareButtons from "components/ShareButtons";
import React, { useEffect, useMemo, useState } from "react";
import useGetRecipient from "hooks/useGetRecipient";
import { resolveRoute } from "helpers/router";
import LoadingIndicator from "components/LoadingIndicator";
import LanguageProvider from "components/LanguageProvider/LanguageProvider";
import useGetTranslationFiles from "components/LanguageProvider/utils/getTranslationFiles";

const SuccessPage = () => {
  const [shareContent, setShareContent] = useState({});
  const [urlData, setURLData] = useState();
  const [fetchingData, setFetchingData] = useState(false);
  const { getRecipient } = useGetRecipient();
  const { translations } = useGetTranslationFiles();
  useMemo(
    () =>
      resolveRoute(window.location.href).then((result) => {
        setURLData(result);
      }),
    [],
  );

  useEffect(() => {
    if (!urlData || fetchingData || Object.keys(shareContent).length !== 0) {
      return;
    }
    setFetchingData(true);
    getRecipient(urlData.params.type, urlData.params.id).then((response) => {
      const mappedShareData = mapShareData(response.data.recipient);
      setShareContent(mappedShareData);
      setFetchingData(false);
    });
  }, [urlData, getRecipient, fetchingData, shareContent]);

  return (
    shareContent?.campaignId ? (
      <LanguageProvider translations={translations}>
        <ShareButtons
          url={shareContent?.url ?? "chuffed.org"}
          round={false}
          quote={shareContent?.quote}
          title={shareContent?.title}
          campaignId={shareContent?.campaignId}
          emailShareText="Email"
          emailShareSubject="Have you heard about this?"
          emailShareBody="Hi! I thought you might be interested in this campaign -"
          messengerShareText="Messenger"
          whatsappShareText="WhatsApp"
          facebookShareText="Facebook Post"
          twitterShareText="Twitter"
          copyText="Copy Url"
          trackingEvent={shareContent?.trackingEvent}
          trackingSource={shareContent?.trackingSource}
          type="full-width"
        />
      </LanguageProvider>
    )
      : <LoadingIndicator />
  );
};

const mapShareData = (donationData) => {
  const campaign = donationData;
  const imageUrl = donationData.assets[0]?.publicUrl ?? "";
  return {
    url: donationData.url,
    image: imageUrl,
    campaignId: campaign.id,
    title: campaign.title,
    quote: campaign.title,
    trackingEvent: "donation_success_share",
    trackingSource: "donation_success_page",
  };
};
export default SuccessPage;
