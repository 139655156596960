var _a, _b;
import { startsWith } from 'lodash';
const cdnPrefix = typeof window !== 'undefined' ? (_b = (_a = window.Chuffed) === null || _a === void 0 ? void 0 : _a.CDN.assets) !== null && _b !== void 0 ? _b : '' : '';
const url = (uri) => {
    let qualifiedUri = uri;
    if (!startsWith(uri, '/')) {
        qualifiedUri = `/${uri}`;
    }
    return qualifiedUri;
};
const asset = (uri) => {
    if (uri.startsWith('http')) {
        return uri;
    }
    return `${cdnPrefix}${url(uri)}`;
};
export default asset;
