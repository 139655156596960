export const generateCampaignCard = (targetRecipient, donationFormData) => {
  const urlPrefix = targetRecipient?.id !== Number(donationFormData?.recipientId) ? "fundraiser" : "project";
  return ({
    currency: donationFormData?.campaignCurrency.toUpperCase(),
    currencySymbol: donationFormData?.campaignCurrencySymbol,
    image: targetRecipient?.camp_image,
    isActive: targetRecipient?.camp_status === "live" || donationFormData?.recipient.camp_status === "live",
    moneyRaised: targetRecipient?.camp_amount_collected,
    target: targetRecipient?.camp_amount,
    title: targetRecipient?.title,
    url: `/${urlPrefix}/${targetRecipient?.slug}`,
    user_image: targetRecipient?.photo,
  });
};
