export const remapImpactLevels = (levels) => {
  if (!levels) {
    return [];
  }

  return levels.map((i) => (
    {
      amount: i.value.amount / 100,
      id: i.id,
      title: i.label,
    }
  ));
};
