import { jsx as _jsx } from "react/jsx-runtime";
import ErrorDisplay from 'components/ErrorDisplay';
import Input from 'components/Input';
import InputGroup from 'components/InputGroup';
import { fieldRequired } from 'widgets/Donation/utils/fieldHelpers';
const AddressLocality = ({ donationFormFields, donorFields: { values: { addressCountry, addressLocality, }, setFieldValue, setFieldTouched, touched, errors, }, }) => {
    if (addressCountry === undefined) {
        return null;
    }
    const reqAddress = fieldRequired(donationFormFields.address.status) ? '*' : '';
    let localityLabel = '';
    let localityPlaceholder = '';
    switch (addressCountry) {
        case 'Australia':
            localityLabel = 'City / Town';
            localityPlaceholder = 'Surry Hills';
            break;
        case 'United Kingdom':
            localityLabel = 'City / Town';
            localityPlaceholder = 'Brixton';
            break;
        case 'United States':
            localityLabel = 'City / Town';
            localityPlaceholder = 'Oakland';
            break;
        default:
            localityLabel = 'City / Locality';
            localityPlaceholder = 'Brixton';
    }
    return (_jsx(InputGroup, { label: `${localityLabel}${reqAddress}`, look: "sentence-case", spacing: "none", className: undefined, description: undefined, inline: undefined, inlineAlign: undefined, inlineWidth: undefined, isFlashHighlight: undefined, size: undefined, v2: undefined, marginBottom: undefined, children: _jsx(ErrorDisplay, { alwaysShowErrors: ((touched === null || touched === void 0 ? void 0 : touched.addressLocality) && ((errors === null || errors === void 0 ? void 0 : errors.addressLocality) !== null)), customMsg: errors === null || errors === void 0 ? void 0 : errors.addressLocality, field: addressLocality, errorKey: null, children: _jsx(Input, { name: "addressLocality", autoComplete: "shipping address-level2", maxLength: 255, placeholder: localityPlaceholder, value: addressLocality, onChange: (e) => setFieldValue('addressLocality', e.target.value), onBlur: () => setFieldTouched('addressLocality', true) }) }) }));
};
export default AddressLocality;
