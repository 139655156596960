import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import './_style.scss';
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
export const generateDonationOptionsArray = (donationOptions) => {
    if (!donationOptions) {
        return [];
    }
    return donationOptions.map((val) => val);
};
export const initialValueInDonationOptions = (donationOptions, initialValue) => (donationOptions ? donationOptions.includes(initialValue) : false);
export const defaultDonationOptions = [25, 50, 100, 200];
const DonationSelector = ({ currencySymbol, donationOptions: donationOptionsProp, handleDonationAmountChange, initialValue, name = 'donationAmount', onChange, resetSelected = false, setFieldValue, setSelectedIndex, value, }) => {
    const [selectedIndex, setLocalSelectedIndex] = useState(null);
    const [otherIsActive, setOtherIsActive] = useState(false);
    const defaultValue = initialValue !== null && initialValue !== void 0 ? initialValue : undefined;
    const { translate: t } = useLanguageProvider();
    const donationOptions = useMemo(() => {
        if (donationOptionsProp) {
            return donationOptionsProp.length === 0 ? defaultDonationOptions : donationOptionsProp;
        }
        return defaultDonationOptions;
    }, [donationOptionsProp]);
    useEffect(() => {
        if (resetSelected) {
            setLocalSelectedIndex(null);
            setSelectedIndex(null);
        }
    }, [resetSelected, setSelectedIndex]);
    const setIndexes = (index) => {
        setLocalSelectedIndex(index);
        setSelectedIndex(index);
    };
    useEffect(() => {
        const optionsArray = generateDonationOptionsArray(donationOptions);
        if (initialValueInDonationOptions(optionsArray, initialValue)) {
            const index = optionsArray.findIndex((i) => i === initialValue);
            setLocalSelectedIndex(index);
            setSelectedIndex(index);
        }
        else {
            setOtherIsActive(!!initialValue);
        }
    }, [donationOptions, initialValue, setSelectedIndex]);
    const handleChange = (event) => {
        var _a;
        const target = (_a = event.currentTarget.getAttribute('data-index')) !== null && _a !== void 0 ? _a : '';
        setIndexes(Number.parseInt(target, 10));
        setOtherIsActive(false);
        if (document.getElementById('donation-selector-option-other')) {
            const otherDonationAmount = document.getElementById('donation-selector-option-other');
            if (otherDonationAmount) {
                otherDonationAmount.value = '';
            }
        }
        const donationAmount = Number.parseFloat(event.currentTarget.value);
        if (!setFieldValue) {
            // Trigger update of value to the redux-form field
            onChange(donationAmount, handleDonationAmountChange(donationAmount));
        }
        else {
            setFieldValue('donationAmount', donationAmount);
            handleDonationAmountChange(donationAmount);
        }
    };
    const handleOtherChange = (event) => {
        setIndexes(-1);
        setOtherIsActive(true);
        if (event.currentTarget.value === '') {
            return;
        }
        const otherValue = Number.parseFloat(event.currentTarget.value);
        const otherValueParsed = Number.isNaN(otherValue)
            ? 0.0
            : Math.round(otherValue * 1e2) / 1e2;
        if (!setFieldValue) {
            // Trigger update of value to the redux-form field
            onChange(otherValueParsed, handleDonationAmountChange(otherValueParsed));
        }
        else {
            setFieldValue('donationAmount', otherValueParsed);
            handleDonationAmountChange(otherValueParsed);
        }
    };
    return (_jsxs("div", { className: "donation-selector", children: [_jsx("input", { type: "hidden", name: name, value: value }), _jsx("div", { className: "donation-selector__options", "data-testid": "donation-selector__options", children: donationOptions.map((donationValue, index) => {
                    const optionBaseClass = 'donation-selector__option';
                    const optionClass = selectedIndex === index
                        ? `${optionBaseClass} --active`
                        : optionBaseClass;
                    return (_jsxs("label", { className: optionClass, htmlFor: `donation-selector-option-${donationValue}`, children: [currencySymbol, donationValue, _jsx("input", { className: "donation-selector__option-input", "data-index": index, id: `donation-selector-option-${donationValue}`, "data-testid": `donation-selector-option-${donationValue}`, name: name, onChange: handleChange, type: "radio", value: donationValue, checked: selectedIndex === index })] }, `donationAmount ${donationValue}`));
                }) }), _jsx("div", { className: "donation-selector__other", children: _jsxs("label", { className: `donation-selector__option-other${otherIsActive ? ' --active' : ''}`, htmlFor: "donation-selector-option-other", children: [_jsx("span", { className: "donation-selector__input-currency", children: currencySymbol }), _jsx("input", { "data-testid": "donation-selector__input-other", className: "donation-selector__input-other", id: "donation-selector-option-other", min: "0", onChange: handleOtherChange, onFocus: handleOtherChange, placeholder: t('Enter amount'), step: "0.01", type: "number", defaultValue: defaultValue })] }) })] }));
};
export default DonationSelector;
