const generateCountryOptions = (countries, defaultCountry) => {
    const options = [];
    options.push({
        name: defaultCountry,
        text: defaultCountry,
        value: defaultCountry,
    });
    countries.forEach((country) => {
        if (country !== defaultCountry) {
            options.push({
                name: country,
                text: country,
                value: country,
            });
        }
    });
    return options;
};
export default generateCountryOptions;
export const generateAURegionOptions = (auRegions) => {
    const options = [];
    options.push({
        name: 'default',
        text: 'Please select',
        value: '',
    });
    auRegions.forEach((region) => {
        options.push({
            name: region,
            text: region,
            value: region,
        });
    });
    return options;
};
export const generateUSRegionOptions = (usRegions) => {
    const options = [];
    options.push({
        name: 'default',
        text: 'Please select',
        value: '',
    });
    usRegions.forEach((region) => {
        options.push({
            name: region.value,
            text: region.text,
            value: region.value,
        });
    });
    return options;
};
export const getPhonePlaceHolder = (addressCountry) => {
    switch (addressCountry) {
        case 'Australia':
            return '+61 400 123 456';
            break;
        case 'Canada':
        case 'United States':
            return '+1 555 123 4567';
            break;
        default:
            return '+44 7123 456789';
    }
};
