import React from "react";

import { formatCurrency } from "helpers/formatCurrency";
import { calculatePlatformFeeTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import { fieldIsEnabled } from "widgets/Donation/utils/fieldHelpers";
import TextTranslator from "components/TranslateText/TextTranslator";

export const getDisclaimerField = (customFields) => customFields && customFields.filter((field) => {
  if (field.id !== "disclaimer") {
    return;
  }

  return fieldIsEnabled(field.status) && field.label.trim().length > 0;
});

export const createDisclaimer = (donationFormData, i, formProps) => {
  const { feeStructure, requiresOptIn, donationFormCustomFields } = donationFormData;
  const formValues = formProps.values;
  const disclaimerField = getDisclaimerField(donationFormCustomFields);

  return (
    <div className="donation-form__terms" data-test="donation-form-disclaimer">
      {
        disclaimerField?.length > 0
        && (
        <div className="donation-form__campaigner-disclaimer" data-test="donation-form-disclaimer-text">
          {disclaimerField[0].label}
        </div>
        )
        }
      {
          feeStructure.optionalPlatformDonations === false
          && (
          <span>
            <TextTranslator
              replacements={{ fee: formatCurrency(calculatePlatformFeeTotal(formValues), feeStructure, true) }}
            >
              Chuffed.org is a social enterprise that believes in
              transparency. :fee will be deducted from your donation to cover payment processing fees,
              customer support, training for the nonprofit sector and developing the best crowdfunding
              platform for nonprofit, charity and social good campaigners in the world.
            </TextTranslator>
            <br />
            <br />
          </span>
          )
        }
      {
          requiresOptIn
          && (
          <span>
            <TextTranslator>
              Chuffed.org does not sell or swap your personal data. We
              process it securely via our global payment partners and store it securely in
              our firewalled database in Australia. You can see exactly how we use and
              protect your data in our privacy policy.
            </TextTranslator>
            <br />
            <br />
          </span>
          )
        }
      <TextTranslator>By clicking below I agree to Chuffed’s</TextTranslator>
      {" "}
      <a target="_blank" href="/terms-conditions/"><TextTranslator>terms and conditions</TextTranslator></a>
      {" "}
      &amp;
      {" "}
      <a target="_blank" href="/privacy"><TextTranslator>privacy policy</TextTranslator></a>
      .
    </div>
  );
};
