import React from "react";
import mountApps from "utils/appBootStrap";
import { client } from "helpers/apolloClientLink";
import { ApolloProvider } from "@apollo/client";
import DonationPage from "./DonationPage";
import SuccessPage from "./SuccessPage";

window.addEventListener("DOMContentLoaded", () => {
  mountApps({
    "js-donation-root": () => <DonationPage />,
    "js-donation-success-root": () => <ApolloProvider client={client}><SuccessPage /></ApolloProvider>,
  });
});
