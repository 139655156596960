import React from "react";
import { formatCurrency } from "helpers/formatCurrency";
import DonationDonorDetails from "../DonationDonorDetails/DonationDonorDetails";

export const formatDonationDonorDetails = (donationFormFields) =>
  donationFormFields.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

export const renderDonorDetails = (data, formProps) => {
  const {
    defaultCountry,
    feeStructure,
    giftAidEnabled,
    giftAidOfficialName,
    recipientOwner,
    requiresOptIn,
    donationFormFields,
  } = data;

  const {
    values: {
      donationAmount,
    },
  } = formProps;

  const formattedAmount = formatCurrency(donationAmount, feeStructure, true);

  return (
    <DonationDonorDetails
      data-test="donation-donor-details"
      defaultCountry={defaultCountry}
      donationFormFields={formatDonationDonorDetails(donationFormFields)}
      donorFields={formProps}
      giftAid={{
        enabled: giftAidEnabled || false,
        formattedAmount: formattedAmount || false,
        officialName: giftAidOfficialName,
      }}
      recipientOwner={recipientOwner}
      requiresOptIn={requiresOptIn}
    />
  );
};
