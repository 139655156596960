import { useField } from "formik";
import React, { useEffect, useState } from "react";

import DonationSelector, { defaultDonationOptions } from "components/DonationSelector/DonationSelector";
import ErrorDisplay from "components/ErrorDisplay";

import { usePrevious } from "helpers/previousHook";
import { createDonationTotal } from "widgets/Donation/components/DonationTotal/createDonationTotal";
import { isFlatFeeModel } from "widgets/Donation/utils/helpers";
import { frequencyLabel } from "widgets/Donation/utils/regularGivingHelpers";

export const generateDonationOptions = (donationFrequency, donationFormData) => {
  let options = defaultDonationOptions;

  if (donationFormData?.donationOptions) {
    options = donationFormData.donationOptions;
  }

  return options;
};

export const DonationSelectorWrapper = ({ label, ...props }) => {
  const frequency = props.values.frequency;
  const [selectedIndex, setSelectedIndex] = useState();
  const previousFrequency = usePrevious(frequency);
  const [field, meta] = useField(props);
  const {
    feeStructure,
  } = props.data;

  const { handleDonationAmountChanges } = props.handlers;
  const openCurrencyModal = props.handlers.openCurrencyModal();
  const regularGivingContext = { ...props.data, ...props };
  const hasFrequencyLabel = frequencyLabel(true, true, regularGivingContext);
  const hasRegularGiving = props.data.regularGiving.enabled;
  const donationOptions = generateDonationOptions(frequency, props.data);

  /* eslint react-hooks/exhaustive-deps: "off" */
  useEffect(() => {
    if (props?.data?.prefill?.donationAmount) {
      handleDonationAmountChanges(
        props?.data?.prefill?.donationAmount,
        props.data,
        props,
        props.donationFormContext.optionalTier,
      );
    }
  }, [props?.data]);

  useEffect(() => {
    // update donation amount if frequency is different and defaultPaymentMethod is regular (allows different donation options based on regular giving toggle)
    if (previousFrequency && (frequency !== previousFrequency) && props?.data?.defaultPaymentMethod === "regular") {
      const updatedDonationValue = Object.values(donationOptions)[selectedIndex];
      props.setFieldValue("donationAmount", updatedDonationValue);
      handleDonationAmountChanges(updatedDonationValue, props.data, props, props.donationFormContext.optionalTier);
    }
  }, [frequency, previousFrequency, donationOptions, handleDonationAmountChanges, props, selectedIndex]);

  const changeDonationAmount = (
    value,
  ) => handleDonationAmountChanges(value, props.data, props, props.donationFormContext.optionalTier);
  const donationTotal = (isFlatFeeModel(feeStructure) && !hasRegularGiving)
    ? createDonationTotal(feeStructure, openCurrencyModal, props, hasFrequencyLabel)
    : null;

  return (
    <section>
      <ErrorDisplay
        alwaysShowErrors={meta.touched && meta.error?.length > 0}
        customMsg={meta.error}
        field={field}
      >
        <DonationSelector
          currencySymbol={feeStructure.currencySymbol}
          donationOptions={donationOptions}
          setFieldValue={(value) => props.setFieldValue("donationAmount", value)}
          handleDonationAmountChange={changeDonationAmount}
          setSelectedIndex={setSelectedIndex}
          initialValue={props?.data?.prefill?.donationAmount}
          {...field}
        />
      </ErrorDisplay>
      {donationTotal}
    </section>
  );
};

export const createDonationSelector = (data, i, formProps, handlers, donationFormContext) => (
  <DonationSelectorWrapper
    key={i}
    label="Donation Selector"
    name="donationAmount"
    type="text"
    data={data}
    handlers={handlers}
    donationFormContext={donationFormContext}
    {...formProps}
  />
);
