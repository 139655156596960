import React from "react";

import { formatCurrency } from "helpers/formatCurrency";

import "./_style.scss";
import TextTranslator from "components/TranslateText/TextTranslator";

export const createProcessingFee = (data, i, formProps) => {
  const {
    values: {
      feeAmount,
    },
  } = formProps;

  const feeStructure = data.feeStructure;

  return (
    <div data-test="donation-form-fee" className="donation-form__line-item --fee donation-fee__container" key={i}>
      <input type="hidden" name="feeAmount" value={feeAmount ?? ""} />
      <span className="donation-form__line-item-label">
        <TextTranslator>
          Payment processing fees:
        </TextTranslator>
      </span>
      <span className="donation-form__line-item-value" data-testid="donation-processing-fee">
        { formatCurrency(feeAmount, feeStructure, false) }
      </span>
    </div>
  );
};
