import React from "react";
import { BsExclamationCircle } from "@react-icons/all-files/bs/BsExclamationCircle";

import { IconStateCancelled } from "components/Icon";
import {
  normalizeErrorMessages,
  normalizeNetworkErrors,
  getErrorDisplay,
} from "./normalizeErrorMessages";

import "./_style.scss";

const ErrorHandler = ({
  errors,
  networkError,
  graphQLErrors,
}) => {
  const networkErrorResolved = networkError && networkError.map((error, i) => (
    <React.Fragment key={i}>
      {getErrorDisplay(error.message, normalizeNetworkErrors)}
    </React.Fragment>
  ));

  if (graphQLErrors) {
    return <p>Unable to load requested page. Please try again later.</p>;
  }

  if (!errors && !networkError) {
    return null;
  }

  if (networkError && networkErrorResolved) {
    return (
      <div className="error-handler">
        {
          networkError && networkErrorResolved
            ? networkErrorResolved
            : (
              <p>
                <span className="error-handler__icon"><BsExclamationCircle color="#FFF" /></span>
                Something went wrong, please refresh the page.
              </p>
            )
      }
      </div>
    );
  }

  return (
    <div>
      <p className="error-handler__subheading">
        <span className="error-handler__icon"><IconStateCancelled width="1em" height="2em" /></span>
        Unable to save
      </p>
      <div className="error-handler__query-error" data-test="error-handler-query-error">
        {
          errors?.map((error, i) => (
            <React.Fragment key={i}>
              {getErrorDisplay(error.message, normalizeErrorMessages, (error?.extensions?.[error.message] || ""))}
            </React.Fragment>
          ))
        }
      </div>
    </div>
  );
};

export default ErrorHandler;
