import { filterCustomFields, filterDefaultFields } from "./filterDonationFormFields";
import { remapFrequencyValues } from "./remapFrequencyValues";
import { remapPrefillValues } from "./remapPrefillValues";
import { remapPaymentVendors } from "./remapPaymentVendors";
import { remapFeeStructure } from "./remapFeeStructure";
import { remapImpactLevels } from "./remapImpactLevels";
import { remapFundraisers, remapIndividualFundraiser } from "./remapFundraisers";
import { remapCollectivePerks, remapSelectedPerk } from "./remapPerks";

export const verifyCampaignData = (campaignData, initialPayload) => {
  if (!campaignData || !initialPayload) {
    return false;
  }

  if (!campaignData.financialInformation.paymentMethods) {
    return false;
  }

  if (!campaignData.donationForm) {
    return false;
  }

  if (!campaignData.feeStructure) {
    return false;
  }

  if (!campaignData.owner) {
    return false;
  }

  return true;
};

export const mapDonationFormData = (campaignData, initialPayload, fundraiserId = null) => {
  if (!verifyCampaignData(campaignData, initialPayload)) {
    throw Error("Campaign not found");
  }

  const paymentVendors = remapPaymentVendors(campaignData.financialInformation.paymentMethods);
  const feeStructure = campaignData.feeStructure;
  const fundraiserData = fundraiserId
    ? campaignData.fundraisers.edges.find((fundraiser) => fundraiser.node.id === fundraiserId).node
    : null;

  return {
    campaignCurrency: feeStructure.currency,
    campaignCurrencySymbol: feeStructure.currency_symbol,
    defaultCountry: campaignData.feeStructure.country?.name || "Australia",
    donationFormCustomFields: campaignData.donationForm.fields
      ? filterCustomFields(campaignData.donationForm.fields)
      : [],
    donationFormFields: campaignData.donationForm
      ? filterDefaultFields(campaignData.donationForm.fields)
      : [],
    donationOptions: campaignData.donationForm.defaultValueAmounts,
    feeStructure: remapFeeStructure(feeStructure, campaignData.financialInformation),
    financialInformation: campaignData.financialInformation,
    fundraisers: remapFundraisers(campaignData.fundraisers.edges),
    giftAidEnabled: campaignData.giftAid?.isEnabled,
    giftAidOfficialName: campaignData.giftAid?.officialName,
    levels: campaignData.donationForm.levels && remapImpactLevels(campaignData.donationForm.levels),
    defaultPaymentMethod: campaignData.donationForm.defaultPaymentMethod === "OneOff" ? "one_off" : "regular",
    matchedGiving: campaignData.matchedGivings && campaignData.matchedGivings.length === 1,
    matchedGivingMatchers: campaignData.matchedGivings
      && campaignData.matchedGivings.length >= 1
      && campaignData.matchedGivings[0].title,
    ownerName: campaignData.ownerName,
    perks: remapCollectivePerks(campaignData.perks),
    recipient: {
      camp_amount: Number(campaignData.target?.amount) / 100,
      camp_amount_collected: Number(campaignData.collected?.amount) / 100,
      camp_image: (campaignData.assets && campaignData.assets.length >= 1) && campaignData.assets[0].url,
      camp_status: campaignData.status,
      id: Number(campaignData.id),
      photo: null,
      slug: campaignData.slug,
      title: campaignData.title,
      type: initialPayload.recipientType || "campaign", // missing from campaign query
    },
    recipientId: campaignData.id,
    recipientOwner: campaignData.ownerName,
    regularGiving: remapFrequencyValues(campaignData.donationForm.frequency),
    requiresOptIn: campaignData.donationForm.requiresOptInMarketing,
    selectedPerk: initialPayload.perkId && campaignData.perks ? remapSelectedPerk(campaignData.perks) : null,
    customTemplate: campaignData.donationForm.template,
    prefill: remapPrefillValues(campaignData.prefill),
    isRepeatDonor: {
      isVerified: campaignData.repeatDonor?.isVerified || false,
      rememberPaymentDetails: campaignData.repeatDonor?.rememberPaymentDetails,
      rememberPaymentMethodDetails: campaignData.repeatDonor?.rememberPaymentMethodDetails,
    },
    paymentVendors,
    paymentMethodPayPal: paymentVendors ? paymentVendors.includes("paypal") : false,
    paymentMethodStripe: paymentVendors ? paymentVendors.includes("stripeau") : false,
    paypalMarketplace: paymentVendors ? paymentVendors.includes("paypal") : false,
    recipientType: initialPayload.recipientType, // missing from campaign query
    directDebitCheckoutSession: campaignData.createDirectDebit?.session,
    directDebitCustomer: campaignData.createDirectDebit?.customer,
    stripeDirectDebit: (campaignData.createDirectDebit?.customer && campaignData.createDirectDebit?.session),
    target: fundraiserId && remapIndividualFundraiser(fundraiserData),
    donationFormMode: campaignData.donationForm.mode,
  };
};
