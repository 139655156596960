import React from "react";
import PropTypes from "prop-types";
import reactCSS from "reactcss";
import classNames from "classnames";

import "./_style.scss";

const InputAddon = ({
  addOnLeft,
  addOnLeftStyle,
  addOnRight,
  children,
  isInvalid,
  classes,
  ...other
}) => {
  const styles = reactCSS({
    "size-small": {
      addon: {
        fontSize: ".9rem",
      },
    },
  }, other);
  return (
    <div
      className={
        classNames(
          "input-group-addon",
          classes,
        )
      }
    >
      {
        addOnLeft
          ? (
            <div
              className="input-group-addon__addon"
              style={({ ...styles.addon, ...addOnLeftStyle })}
            >
              {addOnLeft}
            </div>
          )
          : null
      }
      {
        React.cloneElement(
          children,
          { className: "input-group-addon__input", isInvalid },
        )
      }
      {
        addOnRight
          ? (
            <div
              className="input-group-addon__addon"
              style={styles.addon}
            >
              {addOnRight}
            </div>
          )
          : null
      }
    </div>
  );
};

InputAddon.propTypes = {
  addOnLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  addOnRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  isInvalid: PropTypes.bool,
};

InputAddon.defaultProps = {
  addOnLeft: null,
  addOnRight: null,
  isInvalid: false,
};

export default InputAddon;
