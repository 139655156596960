import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DebounceInput } from 'react-debounce-input';
import { Interweave } from 'interweave';
import classNames from 'classnames';
import { FcCheckmark } from '@react-icons/all-files/fc/FcCheckmark';
import LoadingIndicator from '../LoadingIndicator';
import './_style.scss';
const Input = ({ autoComplete, active, className, dataTestId, infoMsg, isLoading, isInvalid, isDisabled, isTextOnly, isRequired, maxLength, name, onChange, onBlur, placeholder, size, successMsg, type, value, min, }) => (_jsxs("span", { className: classNames('input', className), "data-testid": "input-component", children: [_jsx(DebounceInput, { autoComplete: autoComplete, className: classNames('input__element', `--${size}`, {
                '--isRequired': isRequired,
                '--isInvalid': isInvalid,
                '--text-only': isTextOnly,
            }), "data-testid": dataTestId, debounceTimeout: 500, disabled: isDisabled, maxLength: maxLength, name: name, onBlur: onBlur, onChange: onChange, placeholder: placeholder, readOnly: isTextOnly, required: isRequired, type: type, value: value, min: min }), successMsg && (_jsxs("span", { className: "input__successMsg", children: [_jsx(FcCheckmark, { size: "10", color: "#28ab60", style: { marginRight: '3px' } }), successMsg] })), active && infoMsg && (_jsx("div", { className: "input__infoMsg", children: _jsx(Interweave, { content: infoMsg }) })), _jsx("div", { className: "input__infoMsg", children: _jsx(Interweave, { content: infoMsg }) }), isLoading && (_jsx(LoadingIndicator, { className: "input__loadingIndicator", size: "10px" }))] }));
Input.defaultProps = {
    autoComplete: null,
    active: false,
    className: '',
    dataTestId: '',
    infoMsg: '',
    isInvalid: false,
    isDisabled: false,
    isLoading: false,
    isRequired: false,
    isTextOnly: false,
    maxLength: 255,
    onBlur: (event) => event,
    placeholder: null,
    size: 'regular',
    successMsg: '',
    type: 'text',
    value: null,
    min: null,
};
export default Input;
