import PropTypes from "prop-types";
import React, { Component } from "react";

import Checkbox from "components/Checkbox";
import CustomSelect from "components/CustomSelect";
import ErrorDisplay from "components/ErrorDisplay";
import Input from "components/Input/Input";
import InputGroup from "components/InputGroup";
import InputRow from "components/InputRow";

import validateBoolean from "helpers/validateBoolean";

import { fieldRequired } from "widgets/Donation/utils/fieldHelpers";

class DonationCustomFields extends Component {
  static propTypes = {
    formFields: PropTypes.array,
    questions: PropTypes.array,
  };

  renderTextField = (field, formField, index, formProps) => {
    const {
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
    } = formProps;
    const fieldName = `customField${index}`;
    const reqField = fieldRequired(field.status) ? "*" : "";

    return (
      <InputRow key={index}>
        <InputGroup label={`${field.label}${reqField}`} look="sentence-case" spacing="none" v2>
          <ErrorDisplay
            alwaysShowErrors={touched[fieldName] && errors[fieldName]}
            customMsg={errors[fieldName]}
          >
            <Input
              name={fieldName}
              value={formProps.values[fieldName]}
              onChange={(e) => setFieldValue(fieldName, e.target.value)}
              onBlur={() => fieldRequired(field.status) && setFieldTouched(fieldName, true)}
              maxLength={255}
              type="text"
            />
          </ErrorDisplay>
        </InputGroup>
      </InputRow>
    );
  };

  renderToggle = (field, formField, index, formProps) => {
    const {
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
      values,
    } = formProps;

    const fieldName = `customField${index}`;
    const reqField = fieldRequired(field.status) ? "*" : "";

    return (
      <InputRow type="checkbox" key={index}>
        <InputGroup label="" spacing="none" v2>
          <ErrorDisplay
            alwaysShowErrors={touched[fieldName] && errors[fieldName]}
            customMsg={errors[fieldName]}
          >
            <Checkbox
              name={fieldName}
              onChange={() => {
                setFieldValue(fieldName, !values[fieldName]);
                fieldRequired(field.status) && setFieldTouched(fieldName, true);
              }}
              checked={validateBoolean(values[fieldName])}
              checkedLabel={`${field.label}${reqField}`}
              uncheckedLabel={`${field.label}${reqField}`}
              spacing="none"
            />
          </ErrorDisplay>
        </InputGroup>
      </InputRow>
    );
  };

  renderDropdown = (field, formField, index, formProps) => {
    const {
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
    } = formProps;

    const fieldName = `customField${index}`;
    const reqField = fieldRequired(field.status) ? "*" : "";
    const selectOptions = [{
      value: "",
      text: "Please select",
      name: "default",
    }];
    field.options.forEach((option) => {
      selectOptions.push({
        value: option,
        text: option,
        name: option,
      });
    });

    return (
      <InputRow key={index}>
        <InputGroup label={`${field.label}${reqField}`} look="sentence-case" spacing="none" v2>
          <ErrorDisplay
            alwaysShowErrors={touched[fieldName] && errors[fieldName]}
            customMsg={errors[fieldName]}
          >
            <CustomSelect
              name={fieldName}
              value={formProps.values[fieldName]}
              onChange={(e) => setFieldValue(fieldName, e.target.value)}
              onBlur={() => fieldRequired(field.status) && setFieldTouched(fieldName, true)}
              options={selectOptions}
            />
          </ErrorDisplay>
        </InputGroup>
      </InputRow>
    );
  };

  render() {
    const {
      formFields,
      questions,
      formProps,
    } = this.props;

    return (
      <div className="donation-form__custom-fields">
        {questions.map((question, index) => {
          const normalizeQuestionType = question.type.toLowerCase();
          switch (normalizeQuestionType) {
            case "text":
              return this.renderTextField(question, formFields[index], index, formProps);
            case "checkbox":
              return this.renderToggle(question, formFields[index], index, formProps);
            case "select":
              return this.renderDropdown(question, formFields[index], index, formProps);
            default:
              return null;
          }
        })}
      </div>
    );
  }
}

export default DonationCustomFields;
