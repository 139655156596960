import { toLower, upperFirst } from "lodash";

export const shouldShowFrequency = (regularGiving, selectedPerk) => {
  if (selectedPerk) {
    if (selectedPerk?.regular_giving_weekly === true
        || selectedPerk?.regular_giving_monthly === true
        || selectedPerk?.regular_giving_yearly === true) {
      return true;
    }

    return false;
  }

  if (regularGiving?.enabled === true) {
    // At least one option needs to be configured on
    if (regularGiving?.weekly === true
        || regularGiving?.monthly === true
        || regularGiving?.yearly === true) {
      return true;
    }
  }

  return false;
};

export const frequencyLabel = (isShort = true, leadingSpace = false, regularGivingContext) => {
  if (!regularGivingContext) {
    return "";
  }

  const {
    values: {
      frequency,
    },
    perk,
    regularGiving,
  } = regularGivingContext;

  const space = (leadingSpace === true) ? " " : "";
  const prefix = (isShort === true) ? "/" : "per ";

  if (shouldShowFrequency(regularGiving, perk) === false) {
    return "";
  }

  if (!frequency || frequency === "one-off" || !regularGivingContext) {
    return "";
  }

  return `${space}${prefix}${frequency}`;
};

export const generateNextDonationDate = (date, frequency) => {
  const donationDate = date;
  const now = date;
  if (frequency === "week") {
    donationDate.setDate(now.getDate() + 7);
    return donationDate;
  }

  if (frequency === "month") {
    if (now.getDate() >= 29) {
      donationDate.setDate(28);
    }
    donationDate.setMonth(now.getMonth() + 1);
    return donationDate;
  }

  if (frequency === "year") {
    donationDate.setFullYear(now.getFullYear() + 1);
    return donationDate;
  }
};

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];

export const nextDonationDate = (date, frequency) => {
  const nextDate = generateNextDonationDate(date, frequency);
  const nextDonationDay = nextDate.getDate();
  const nextDonationMonth = monthNames[nextDate.getMonth()];
  const nextDonationYear = nextDate.getFullYear();

  return `${nextDonationDay} ${nextDonationMonth} ${nextDonationYear}`;
};

export const generateFrequencyObject = (value) => {
  const frequencyValue = value !== "one-off" ? `${value}ly` : "one-off";
  const textValue = upperFirst(toLower(frequencyValue));

  return {
    name: "frequency",
    text: textValue,
    value,
  };
};

export const shouldReverseFrequencyOptions = (donationData) => donationData.defaultPaymentMethod === "regular";

export const getFrequencyOptions = ({ ...props }) => {
  const frequencyOptions = [];

  // continue showing regular giving toggle but with only one-off if paypal selected
  if (props?.values?.paymentMethod && props?.values?.paymentMethod === "paypal") {
    return frequencyOptions;
  }

  if (!props.data.regularGiving?.isManage) {
    frequencyOptions.push(generateFrequencyObject("one-off"));
  }

  if (props?.data?.regularGiving?.weekly === true || props?.data?.selectedPerk?.regular_giving_weekly === true) {
    frequencyOptions.push(generateFrequencyObject("week"));
  }

  if (props?.data?.regularGiving?.monthly === true || props?.data?.selectedPerk?.regular_giving_monthly === true) {
    frequencyOptions.push(generateFrequencyObject("month"));
  }

  if (props?.data?.regularGiving?.yearly === true || props?.data?.selectedPerk?.regular_giving_yearly === true) {
    frequencyOptions.push(generateFrequencyObject("year"));
  }

  if (shouldReverseFrequencyOptions(props.data)) {
    return frequencyOptions.reverse();
  }

  // if perk cannot be one_off, remove the one_off selection
  if (props?.data?.selectedPerk && !props?.data?.selectedPerk?.one_off) {
    return frequencyOptions.filter((i) => i.value !== "one-off");
  }

  return frequencyOptions;
};
