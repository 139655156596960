const genericModalStyles = {
  content: {
    background: "#fff",
    maxWidth: "480px",
    overflow: "auto",
    padding: "2rem 2rem 2rem",
    textAlign: "left",
    width: "95%",
  },
  overlay: {
    backgroundColor: "rgba(51,51,51,.8)",
    overflowY: "auto",
    transform: "translate3d(0, 0, 0)",
    zIndex: 210,
  },
};

export default genericModalStyles;
