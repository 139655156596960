import { isEmptyObject } from "helpers/emptyObject";
import { getFrequencyOptions } from "widgets/Donation/utils/regularGivingHelpers";
import { createObjectFromArray } from "helpers/createObjectFromArray";
import { fieldIsEnabled } from "widgets/Donation/utils/fieldHelpers";

export const enabledCustomFields = (
  customFields,
) => customFields.filter((field) => fieldIsEnabled(field.status) && field.id !== "disclaimer");

export const prefillCustomQuestions = (prefill, fields) => {
  if (!fields || (prefill && isEmptyObject(prefill)) || !prefill) {
    return;
  }

  Object.keys(fields).forEach((field) => {
    if (prefill[field]) {
      fields[field] = prefill[field];
    }
  });

  return fields;
};

export const createCustomQuestionFields = (customFields, prefill) => {
  if (!customFields) {
    return;
  }

  const customFormFields = [];

  const filterDisclaimerCustomFields = enabledCustomFields(customFields);

  filterDisclaimerCustomFields.forEach((field, index) => {
    customFormFields.push(`customField${index}`);
  });

  const fields = createObjectFromArray(customFormFields);

  prefillCustomQuestions(prefill, fields);

  return fields;
};

export const preselectOptIn = (requiresOptIn) => !requiresOptIn;
export const preselectPaymentMethod = (paymentVendors) => (paymentVendors && (paymentVendors.length === 1 && paymentVendors.includes("paypal") ? "paypal" : null));
export const isRegularGivingPreselect = (prefill) => prefill?.forRegularGiving || false;

export const mergePrefillData = (donationData) => ({
  addressCountry: donationData.prefill?.address ? donationData.prefill?.address?.country : donationData.addressCountry,
  addressLine1: donationData.prefill?.address ? donationData.prefill?.address?.line1 : donationData.addressLine1,
  addressLocality: donationData.prefill?.address
    ? donationData.prefill?.address?.locality
    : donationData.addressLocality,
  addressRegion: donationData.prefill?.address
    ? donationData.prefill?.address?.region
    : donationData.addressRegion,
  addressPostcode: donationData.prefill?.address
    ? donationData.prefill?.address?.postcode
    : donationData.addressPostcode,
  chuffedAmount: donationData.chuffedAmount || 0,
  donationAmount: donationData.prefill?.donationAmount
    ? donationData.prefill?.donationAmount
    : (donationData.donationAmount || 0),
  email: donationData.prefill?.email ? donationData.prefill?.email : donationData.email,
  feeAmount: donationData.feeAmount || 0,
  firstName: donationData.prefill?.firstName ? donationData.prefill?.firstName : donationData.firstName,
  frequency: donationData.prefill?.frequency ? donationData.prefill?.frequency : (donationData.frequency || (getFrequencyOptions({ data: donationData }).length >= 1 && getFrequencyOptions({ data: donationData })[0].value) || "one-off"),
  isGiftAidDeclared: donationData.isGiftAidDeclared,
  isOptIn: preselectOptIn(donationData.requiresOptIn),
  isOptInChuffed: preselectOptIn(donationData.requiresOptIn),
  isShowName: true,
  lastName: donationData.prefill?.lastName ? donationData.prefill?.lastName : donationData.lastName,
  paymentMethod: isRegularGivingPreselect(donationData.prefill) ? "visa-mc" : preselectPaymentMethod(donationData.paymentVendors),
  perksCart: donationData.prefill?.perk_id ? donationData.perks : donationData.selectedPerk,
  phone: donationData.prefill?.phone ? donationData.prefill?.phone : donationData.phone,
  recipientId: donationData.recipientId,
  recipientType: donationData.recipientType,
  targetId: donationData.target?.id,
  title: donationData.prefill?.title ? donationData.prefill?.title : donationData.title,
  setPassword: "",
  rememberToggle: false,
  submitButton: "",
});

export const getInitialValues = (donationData) => {
  if (!donationData) {
    return;
  }

  const values = mergePrefillData(donationData);

  const customFields = donationData?.donationFormCustomFields
    && createCustomQuestionFields(donationData.donationFormCustomFields, donationData.prefill);

  return !isEmptyObject(customFields)
  && customFields !== undefined
    ? { ...customFields, ...values }
    : values;
};
