export const remapFeeStructure = (feeStructure, financialInformation) => ({
  country: feeStructure.country,
  countryCode: feeStructure.country_code,
  currency: feeStructure.currency,
  currencySymbol: feeStructure.currency_symbol,
  economicAreaList: feeStructure.economic_area || feeStructure.country_code,
  feeBlendedFixed: Number(feeStructure.blended_fixed),
  feeBlendedVariable: Number(feeStructure.blended_variable),
  feeDomesticFixed: Number(feeStructure.domestic_fixed),
  feeDomesticVariable: Number(feeStructure.domestic_variable),
  feeDomesticVariableAmex: Number(feeStructure.domestic_variable_amex),
  feeIntlFixed: Number(feeStructure.international_fixed),
  feeIntlVariable: Number(feeStructure.international_variable),
  feeIntlVariableAmex: Number(feeStructure.international_variable_amex),
  feePayPalFixed: Number(feeStructure.paypal_fixed),
  feePayPalVariable: Number(feeStructure.paypal_variable),
  fractionalPart: feeStructure.fractional_part,
  feesByPaymentType: financialInformation.paymentModel.paymentFeeBelowLine === false,
  optionalPlatformDonations: financialInformation.paymentModel.hasOptionalDonations,
  platformFeePercent: financialInformation.paymentModel.platformFeePercent,
});
