import PropTypes from "prop-types";
import React from "react";

import classNames from "classnames";

import "./_style.scss";

const RadioToggle = (props) => {
  const handleChange = (e) => {
    props.field.onChange(e.target.value);
  };
  const {
    defaultValue,
    field,
    look,
    options,
    customColor,
  } = props;
  return (
    <div
      className={classNames({
        "radio-toggle": true,
        [`--${look}`]: look,
      })}
      {...field}
    >
      <div className="radio-toggle__options">
        {options.map((option, index) => {
          const OptionElem = option.disabled === true ? "span" : "label";
          const isChecked = defaultValue === option.value;
          return (
            <OptionElem
              className={classNames({
                "radio-toggle__option": true,
                "--disabled": option.disabled === true,
              })}
              htmlFor={`radio-toggle-option${index}`}
              key={index}
            >
              <input checked={isChecked} className="radio-toggle__option-input" data-index={index} disabled={option.disabled} id={`radio-toggle-option${index}`} name={option.name} onChange={handleChange} type="radio" value={option.value} />
              <span
                className="radio-toggle__toggle"
                style={{
                  backgroundColor: isChecked && customColor,
                  borderColor: isChecked && customColor,
                }}
              >
                {option.text}
              </span>
            </OptionElem>
          );
        })}
      </div>
    </div>
  );
};
RadioToggle.propTypes = {
  defaultValue: PropTypes.string,
  field: PropTypes.object.isRequired,
  look: PropTypes.oneOf(["small"]),
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  })).isRequired,
  customColor: PropTypes.string,
};

export default RadioToggle;
