import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  IconCardAmex,
  IconCardMastercard,
  IconCardPayPal,
  IconCardVisa,
} from "components/Icon/Payment";

import { IconStateCancelled } from "components/Icon";

import classNames from "classnames";

import "./_style.scss";

class PaymentToggle extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    field: PropTypes.object.isRequired,
    look: PropTypes.oneOf(["small"]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    shouldShowFees: PropTypes.bool.isRequired,
  };

  handleChange = (event) => {
    if (this.props.field?.onChange) {
      this.props.field?.onChange(event.target.value);
    }

    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    const {
      defaultValue,
      field,
      look,
      options,
      shouldShowFees,
    } = this.props;

    return (
      (options && options.length >= 1)
        ? (
          <div
            data-test="payment-toggle"
            className={
            classNames({
              "payment-toggle": true,
              [`--${look}`]: look,
            })
          }
            {...field}
          >
            <div className="payment-toggle__options" data-test="payment-toggle__options">
              {options.map((option, index) => (
                <label
                  className="payment-toggle__option"
                  htmlFor={`payment-toggle-option${index}`}
                  data-test={`payment-toggle-option${index}`}
                  key={index}
                >
                  <input
                    checked={defaultValue === option.value}
                    className="payment-toggle__option-input"
                    data-index={index}
                    id={`payment-toggle-option${index}`}
                    name={option.name}
                    onChange={this.handleChange}
                    type="radio"
                    value={option.value}
                  />
                  <span className="payment-toggle__toggle">
                    <span className="payment-toggle__method-icon">
                      {(option.value === "visa-mc")
                        && (
                          <>
                            <IconCardVisa label="Visa" />
                            <IconCardMastercard label="Mastercard" viewBox="0 0 46.48 26.77" />
                          </>
                        )}
                      {(option.value === "amex")
                        && <IconCardAmex label={option.text} />}

                      {(option.value === "paypal")
                        && <IconCardPayPal label={option.text} />}

                    </span>
                    {shouldShowFees === true
                      && (
                      <span className="payment-toggle__method-fee">
                        (
                        {option.fee}
                        )
                      </span>
                      )}
                  </span>
                </label>

              ))}
            </div>
          </div>
        )
        : (
          <div className="payment-toggle__error">
            <IconStateCancelled />
            <p>We've detected abnormal behaviour from your IP address. Please try again in 30 mins.</p>
          </div>
        )
    );
  }
}

export default PaymentToggle;
