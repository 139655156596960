import { useState } from "react";
import GET_RECIPIENT from "queries/getRecipient.gql";
import { useLazyQuery } from "@apollo/client";

const useGetRecipient = () => {
  const [recipient, setRecipient] = useState();

  const [getRecipient, { data, loading, error }] = useLazyQuery(GET_RECIPIENT, {
    onCompleted() {
      if (data?.recipient) {
        setRecipient(data.recipient);
      }
    },
  });
  return {
    getRecipient: (type, id) => getRecipient({ variables: { type, id } }),
    loading,
    recipient,
    error,
  };
};

export default useGetRecipient;
