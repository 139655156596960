// if preselected fundraiser && donor has not changed
export const isPreselectedFundraiserAndUserHasNotChanged = (
  preselectedFundraiser,
  donorSelectedCampaignOrFundraiserId,
) => preselectedFundraiser && !donorSelectedCampaignOrFundraiserId;

// if preselected fundraiser && user has changed fundraiser/campaign input && selection is not campaign id
export const isPreselectedFundraiserAndUserHasChanged = (
  preselectedFundraiser,
  donorSelectedCampaignOrFundraiserId,
  campaignId,
) => (preselectedFundraiser && donorSelectedCampaignOrFundraiserId)
  && !donorSelectedIsCampaign(donorSelectedCampaignOrFundraiserId, campaignId);

export const donorSelectedIsCampaign = (
  donorSelectedCampaignOrFundraiserId,
  campaignId,
) => donorSelectedCampaignOrFundraiserId === campaignId;

export const isCampaignOrUserSelectedCampaign = (
  campaignId,
  preselectedFundraiser,
  donorSelectedCampaignOrFundraiserId,
) => {
  const noSelection = campaignId && !preselectedFundraiser && !donorSelectedCampaignOrFundraiserId;
  const hasSelectionOfCampaign = donorSelectedCampaignOrFundraiserId
    && (donorSelectedCampaignOrFundraiserId === campaignId);
  const isCampaign = noSelection || hasSelectionOfCampaign;

  return isCampaign || false;
};

export const shouldAssignDonationToChallenger = (data) => {
  // if pre-selected fundraiser, use is on page ?f=
  const preselectedFundraiser = data.target?.id ? Number(data.target.id) : null;
  // campaign id is always recipient id
  const campaignId = Number(data.recipientId);
  // user has selected a new fundraiser or campaign to donate to
  const donorSelectedCampaignOrFundraiserId = data.targetId ? Number(data.targetId) : null;

  // if not preselected fundraiser and no changes to selection return early
  if (isCampaignOrUserSelectedCampaign(campaignId, preselectedFundraiser, donorSelectedCampaignOrFundraiserId)) {
    return {
      isChallenger: false,
      recipient_id: donorSelectedCampaignOrFundraiserId || campaignId,
    };
  }

  if (donorSelectedIsCampaign(donorSelectedCampaignOrFundraiserId, campaignId)) {
    return {
      isChallenger: false,
      recipient_id: donorSelectedCampaignOrFundraiserId,
    };
  }

  const preselected = isPreselectedFundraiserAndUserHasNotChanged(
    preselectedFundraiser,
    donorSelectedCampaignOrFundraiserId,
  ) && preselectedFundraiser;
  const userHasFundraiserSelection = isPreselectedFundraiserAndUserHasChanged(
    preselectedFundraiser,
    donorSelectedCampaignOrFundraiserId,
    campaignId,
  ) && donorSelectedCampaignOrFundraiserId;
  const challengerSelectedFromCampaignPage = (!preselected && !userHasFundraiserSelection)
    && donorSelectedCampaignOrFundraiserId;
  const challengerId = preselected || userHasFundraiserSelection || challengerSelectedFromCampaignPage || null;

  return {
    isChallenger: challengerId !== null,
    recipient_type: challengerId ? "challenger" : null,
    recipient_id: challengerId || null,
  };
};
