import PropTypes from "prop-types";
import React, { useState } from "react";

import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import InputGroup from "components/InputGroup";
import LinkButton from "components/LinkButton";
import LinkButtonGroup from "components/LinkButtonGroup";
import CustomSelect from "components/CustomSelect";
import "./_style.scss";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const currencies = [
  {
    currency: "AUD",
    countryIso: "AU",
  },
  {
    currency: "GBP",
    countryIso: "GB",
  },
  {
    currency: "CAD",
    countryIso: "CA",
  },
  {
    currency: "USD",
    countryIso: "US",
  },
  {
    currency: "EUR",
    countryIso: "BE",
  },
];

export const generateCurrencyOptions = (currency) => {
  const options = [];

  const currentCurrency = currency.toUpperCase();

  options.push({
    name: currentCurrency,
    text: currentCurrency,
    value: currentCurrency,
  });

  const currencyName = currencies.flatMap((i) => i.currency);

  currencyName.forEach((currency) => {
    if (currency !== currentCurrency) {
      options.push({
        name: currency,
        text: currency,
        value: currency,
      });
    }
  });

  return options;
};

const modalStyles = {
  content: {
    background: "#fff",
    maxWidth: "480px",
    overflow: "auto",
    padding: "2rem 2rem 2rem",
    textAlign: "left",
    width: "95%",
  },
  overlay: {
    backgroundColor: "rgba(51,51,51,.8)",
    overflowY: "auto",
    transform: "translate3d(0, 0, 0)",
    zIndex: 210,
  },
};
const CurrencyModal = ({
  currencyModalOpen = true,
  currentCurrency,
  submitChangeCurrency,
  exitCurrencyConversion,
}) => {
  const [chosenCurrency, setChosenCurrency] = useState("");
  const { translate: t } = useLanguageProvider();

  const handleCurrencySelectUpdate = (e) => {
    e.preventDefault();
    setChosenCurrency(e.target.value);
  };

  const handleCurrencyChange = (current, chosen, submitChange, modalStatus) => {
    const chosenCurrency = chosen.toLowerCase();

    if (current !== chosenCurrency && chosenCurrency !== "") {
      const countryIso = currencies.find((currency) => currency.currency.toUpperCase() === chosen).countryIso;
      submitChange(countryIso);
      modalStatus();
    } else {
      modalStatus();
    }
  };

  const changeCurrency = () => handleCurrencyChange(
    currentCurrency,
    chosenCurrency,
    submitChangeCurrency,
    exitCurrencyConversion,
  );

  return (
    <Modal
      isOpen={currencyModalOpen}
      contentLabel={t("Currency Conversion")}
      customStyle={modalStyles}
    >
      <ModalContent
        heading={t("Change Currency")}
        action={(
          <LinkButtonGroup>
            <LinkButton
              data-test="currency-conversion-exit"
              isDisabled={false}
              look="plain"
              onClick={exitCurrencyConversion}
            >
              {t("Exit")}
            </LinkButton>
            <LinkButton
              isDisabled={false}
              isLoading={false}
              loadingMessage={t("Saving...")}
              data-test="currency-conversion-save"
              isBold
              look="primary"
              onClick={changeCurrency}
            >
              {t("Save")}
            </LinkButton>
          </LinkButtonGroup>
        )}
        body={(
          <div>
            <p>{t("If you wish to pay in a different currency, choose one of our supported currencies below.")}</p>

            <InputGroup
              label=""
              size="small"
              spacing="small"
              v2
              description={null}
            >
              <CustomSelect
                name="currency-selector"
                className="currency-selector__select"
                onChange={handleCurrencySelectUpdate}
                options={generateCurrencyOptions(currentCurrency)}
              />
            </InputGroup>
          </div>
        )}
      />
    </Modal>
  );
};

CurrencyModal.propTypes = {
  currencyModalOpen: PropTypes.bool,
  submitChangeCurrency: PropTypes.func,
  currentCurrency: PropTypes.string,
  exitCurrencyConversion: PropTypes.func,
};

export default CurrencyModal;
