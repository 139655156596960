import React from "react";

import { formatCurrency } from "helpers/formatCurrency";
import { frequencyLabel, nextDonationDate } from "widgets/Donation/utils/regularGivingHelpers";
import { calculateDonationTotal } from "widgets/Donation/utils/donationCalculatorHelpers";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

export const isValidRegularGivingFrequency = (frequencyValue) => frequencyValue && (frequencyValue === "week" || frequencyValue === "month" || frequencyValue === "year");

export const createRegularGivingFrequencyLabel = (
  data,
  i,
  formProps,
  handlers,
  donationFormContext,
  date = new Date(),
) => {
  const context = { ...data, ...formProps };
  const { feeStructure } = data;
  const donationTotal = calculateDonationTotal(feeStructure, formProps);
  if (!isValidRegularGivingFrequency(formProps.values.frequency)) {
    return (<div key={i} />);
  }

  return (
    <FrequencyLabel
      index={i}
      date={nextDonationDate(date, formProps?.values?.frequency)}
      amount={formatCurrency(donationTotal, feeStructure)}
      frequency={frequencyLabel(false, true, context)}
    />
  );
};

export const FrequencyLabel = ({
  index, date, frequency, amount,
}) => {
  const { translate: t } = useLanguageProvider();
  return (
    <div key={index}>
      <div className="donation-form__line-info">
        { t("After your donation today, your next donation will be on :date and you will continue to donate :amount :frequency after that.", { date, amount, frequency }) }
        { t("You can stop or change this at any time.")}
      </div>
    </div>
  );
};
