import { jsx as _jsx } from "react/jsx-runtime";
import InputGroup from 'components/InputGroup';
import ErrorDisplay from 'components/ErrorDisplay';
import Input from 'components/Input';
import { fieldRequired } from 'widgets/Donation/utils/fieldHelpers';
const AddressPostCode = ({ donationFormFields, donorFields: { values: { addressCountry, addressPostcode, }, setFieldValue, setFieldTouched, touched, errors, }, }) => {
    if (addressCountry === undefined) {
        return null;
    }
    const reqAddress = fieldRequired(donationFormFields.address.status) ? '*' : '';
    let postcodeLabel = '';
    let postcodePlaceholder = '';
    switch (addressCountry) {
        case 'Australia':
            postcodeLabel = 'Postcode';
            postcodePlaceholder = '2010';
            break;
        case 'United Kingdom':
            postcodeLabel = 'Postal Code';
            postcodePlaceholder = 'SW9 8PQ';
            break;
        case 'United States':
            postcodeLabel = 'ZIP Code';
            postcodePlaceholder = '94623';
            break;
        default:
            postcodeLabel = 'ZIP / Postal Code';
            postcodePlaceholder = 'SW9 8PQ';
    }
    return (_jsx(InputGroup, { label: `${postcodeLabel}${reqAddress}`, look: "sentence-case", spacing: "none", v2: true, c: true, className: undefined, description: undefined, inline: undefined, inlineAlign: undefined, inlineWidth: undefined, isFlashHighlight: undefined, size: undefined, marginBottom: undefined, children: _jsx(ErrorDisplay, { alwaysShowErrors: ((touched === null || touched === void 0 ? void 0 : touched.addressPostcode) && ((errors === null || errors === void 0 ? void 0 : errors.addressPostcode) !== null)), customMsg: errors === null || errors === void 0 ? void 0 : errors.addressPostcode, field: addressPostcode, errorKey: undefined, children: _jsx(Input, { name: "addressPostcode", autoComplete: "shipping postal-code", maxLength: 255, placeholder: postcodePlaceholder, value: addressPostcode, onChange: (event) => setFieldValue('addressPostcode', event.target.value), onBlur: () => setFieldTouched('addressPostcode', true) }) }) }));
};
export default AddressPostCode;
